import React, { Component } from 'react';
import API from '../../../../config/Api';
import Swal from 'sweetalert2'
var md5 = require('md5');

class ChangePassword extends Component {
    constructor(props){
       super(props)	
       this.state = {       
            loader:true, 
            admin: '', 
            password:"",  
            current_password:"",  
            new_password:"",
            renew_password:"",        	
            errors:{
                current_password:"", 
                new_password:"",
                renew_password:"",           
            },
            common_error: ''		
        }
        this.getAdminRow();         
        this.handleChange       = this.handleChange.bind(this);	
        this.handleSubmit       = this.handleSubmit.bind(this);	
    }
    async getAdminRow(){ 
		let headers = new Headers();
		headers.append('Content-Type', 'application/json');	
		headers.append('x-access-token', localStorage.getItem('adminToken'));	
		const response = await fetch(API.GET_ME,{		
		method: 'POST',  
		headers: headers,
		body: ''
		});
		const result = await response.json();         
		if(result.status===true){ 			
			this.setState({	
			admin: result.data,   
			password: result.data.password,	            	            			
			loader: false		
			});	  
		} 	
	}   
    handleChange(e){		
		e.preventDefault();
		const { name, value } = e.target;
		let errors = this.state.errors;       
		
		switch(name){
			case 'current_password': 
				if(!value){ 
					errors.current_password = 'Current Password is required';
				}
                else if(md5(value)!==this.state.password){ 
					errors.current_password = 'Invalid Current Password';
				}
				else{
					errors.current_password = '';
				}
				break;
			case 'new_password': 
				if(!value){ 
					errors.new_password = 'New Password is required';
				}
				else{
					errors.new_password = '';
				}
				break;
			case 'renew_password': 	
                if(!value){ 
                    errors.renew_password = 'Re-enter New Password is required';
                }
				else if(value && value!==document.getElementById('new_password').value){ 
					errors.renew_password = 'New Password mismatch';
				}
				else{
					errors.renew_password = '';
				}
				break;			
			default:
				break; 
		}		
		this.setState({
			errors, [name]: value,
			common_error  : ''	
		});
	}
    validateForm(){
		const {		
			current_password,  
			new_password,  
			renew_password			
		} = this.state; 

		let errors                = this.state.errors;  
		let isValid               = true; 
		
		if(!current_password){
			isValid 		         = false;
			errors.current_password  = 'Current Password is required';
		}
        else if(md5(current_password)!==this.state.password){ 
            isValid 		          = false;
            errors.current_password   = 'Invalid Current Password';
        }

		if(!new_password){
			isValid 		       = false;
			errors.new_password    = 'New Password is required';
		}

        if(!renew_password){
			isValid 		       = false;
			errors.renew_password  = 'Re-enter New Password is required';
		}
		if( renew_password && renew_password!==document.getElementById('new_password').value){ 
            isValid 		       = false;
            errors.renew_password  = 'New Password mismatch';
        }		

		this.setState({
			errors : errors			
		});	
		return isValid;		 
	}
    async handleSubmit(e){
		e.preventDefault();		
		if(this.validateForm()){
			let headers = new Headers();
			headers.append('Content-Type', 'application/json');	
			headers.append('x-access-token', localStorage.getItem('adminToken'));

			let form_obj  = {};	
			let form_data = new FormData(e.target);	

			for (let [key, value] of form_data.entries()) {				
				form_obj[key] = value;	
			}	
			
			let body_obj = {				
				password:form_obj.new_password,
				adminID:localStorage.getItem('admin_id')				
			}

			await fetch(API.EDIT_PASSWORD,{		
				method: 'POST',  // *GET, POST, PUT, DELETE, etc.
				headers: headers,
				body: JSON.stringify(body_obj) 			
			});

			this.setState({
				current_password:"",   
				new_password: "",	            	            			
				renew_password: ""
			})
			
			Swal.fire({
				icon: 'success',
				title: 'Congrats!',
				text: 'The Password was updated successfully.',
			})           
			
		}			
	}
    render(){

        const {errors} 	= this.state; 

        return(
            <>
            <form name="cngPassdForm" id="cngPassdForm" method="post" encType="multipart/form-data" onSubmit={this.handleSubmit}>

            <div className="row mb-3">
            <label htmlFor="current_password" className="col-md-4 col-lg-3 col-form-label">Current Password</label>
            <div className="col-md-8 col-lg-9">
            <input name="current_password" type="password" className={`form-control ${errors.current_password ? 'error': ''} `} id="current_password" autoComplete='current-password' 
			value={this.state.current_password}
            onChange={this.handleChange} />
			{errors.current_password && 
			<div className="error-msg">{errors.current_password}</div>    
			}   
            </div>
            </div>

            <div className="row mb-3">
            <label htmlFor="new_password" className="col-md-4 col-lg-3 col-form-label">New Password</label>
            <div className="col-md-8 col-lg-9">
            <input name="new_password" type="password" className={`form-control ${errors.new_password ? 'error': ''} `}  id="new_password" 
			autoComplete='new-password'
			value={this.state.new_password} 
			onChange={this.handleChange} />
			{errors.new_password && 
			<div className="error-msg">{errors.new_password}</div>    
			}   
            </div>
            </div>

            <div className="row mb-3">
            <label htmlFor="renew_password" className="col-md-4 col-lg-3 col-form-label">Re-enter New Password</label>
            <div className="col-md-8 col-lg-9">
            <input name="renew_password" type="password" className={`form-control ${errors.renew_password ? 'error': ''} `}  id="renew_password" autoComplete='renew-password' 
			value={this.state.renew_password} 
			onChange={this.handleChange} />
			{errors.renew_password && 
			<div className="error-msg">{errors.renew_password}</div>    
			}   
            </div>
            </div>

            <div className="row mb-3">
            <label htmlFor="renewPassword" className="col-md-4 col-lg-3 col-form-label"></label>
            <div className="col-md-8 col-lg-9">
            <button type="submit" className="btn btn-primary">Change Password</button>
            </div>
            </div>

            </form>
            </>
        )
    }
}
export default ChangePassword;