import React, { useRef, useState, useEffect } from 'react';
import {Helmet} from "react-helmet";
import { Redirect,Link } from 'react-router-dom';
import Swal from 'sweetalert2'

import { Editor } from '@tinymce/tinymce-react';
import editor_config from '../../../config/tinymce_config';

import Meta from '../../common/Meta';
import API from '../../../config/Api';
import SingleImageUpload from '../../common/single_image_upload/SingleImageUpload';
import all_function from '../../../config/All_function';

let Page_form = (props)=> { 
	
	let pageID = ''
	if(props.match.params.pageID){
		pageID = props.match.params.pageID
	}		

	let page_meta_title     = 'Add Page' 	
	if(pageID){		
		page_meta_title     = 'Edit Page' 
	}	
	let metaData = {
		meta_title			: page_meta_title,
		meta_description	: '',
		meta_keywords		: '',
	} 
	
    
    // https://www.w3schools.com/react/react_useref.asp
	// https://www.tiny.cloud/docs/integrations/react/		
	const editor1_Ref 									= useRef(null);  
    const [imagesize1,set_imagesize1]     				= useState('') 
	const [new_record,set_new_record]     				= useState(false) 
	const [page_title,set_page_title] 					= useState('') 
	const [url,set_url]                   				= useState('') 	
	const [banner_image,set_banner_image]   			= useState('') 	
	const [banner_image_error,set_banner_image_error]	= useState('') 	
	const [banner_text_1,set_banner_text_1] 			= useState('') 
	const [banner_text_2,set_banner_text_2] 			= useState('') 
	const [page_content,set_page_content] 				= useState('') 
	const [meta_title,set_meta_title] 					= useState('') 
	const [meta_keyword,set_meta_keyword] 				= useState('') 
	const [meta_description,set_meta_description] 		= useState('') 
		
	let [error,set_error] = useState({
		page_title:"", 
		url:"",
		banner_image:"",
		page_content:"", 		
	}) 

	let get_imagesize1 = async ()=>{
		let result = await all_function.get_image_size(1)
		set_imagesize1(result.data)	
		console.log(result)
	}
	
	const fetchData = async (pageID) => {		
		let headers = new Headers();
		headers.append('Content-Type', 'application/json');	
		headers.append('x-access-token', localStorage.getItem('adminToken'));
		const response = await fetch(API.PAGE+'/'+pageID,{		
			method: 'GET',  
			headers: headers
		});
		const result = await response.json();  		    
		if(result.status===true){ 	
			set_page_title(result.data.page_title)	
			set_url(result.data.url)
			set_banner_image(result.data.banner_image)	
			set_banner_text_1(result.data.banner_text_1)	
			set_banner_text_2(result.data.banner_text_2)	
			set_page_content(result.data.page_content)	
			set_meta_title(result.data.meta_title)	
			set_meta_keyword(result.data.meta_keyword)	
			set_meta_description(result.data.meta_description)
		} 	
	}
	
	const setSeoUrl = (e) => {		  
		let value   = e.target.value;	
		let seo_url = value.toLowerCase().replace(/[^a-z0-9\s]/gi, '').replace(/[_\s]/g, '-');	
		set_url(seo_url)		
	}
	
	const validateForm = ()=>{
		let error           = {};  
		let isValid         = true;   		
		
		if(!page_title) {
			isValid 		 = false;
			error.page_title = 'Page Title is required';
		}
		if(!url) {
			isValid 		 = false;
			error.url        = 'Url is required';
		}
		// if(!document.getElementById('banner_image').value) { 
		// 	isValid 		     = false;			
		// 	set_banner_image_error('Banner image is required');
		// }
		if(banner_image_error) { 
			isValid 		    = false;			
		}
		if(!page_content) {
			isValid 		    = false;
			error.page_content  = 'Page content is required';
		}	
		set_error(error);		
		if(!isValid){			
			Swal.fire({
				icon: 'warning',
				title: 'Opps!',
				text: 'These is some problem with input.',
			}) 		
		}	
		return isValid;		 
	}
	const handleSubmit = async (e) => {	
		e.preventDefault();	
		if(validateForm()){			
            
			if(pageID){
				let headers = new Headers();			
				headers.append('x-access-token', localStorage.getItem('adminToken'));

				let formData = new FormData(e.target);	
				formData.append('pageID',pageID); 
				formData.append('page_content',page_content); 
				await fetch(API.PAGE+'/'+pageID,{		
					method: 'PUT',  // *GET, POST, PUT, DELETE, etc.
					headers: headers,
					body: formData 			
				});	
				
				Swal.fire({
					icon: 'success',
					title: 'Congrats!',
					text: 'The information was updated successfully.',
				})         
			}
			else{
				let headers = new Headers();			
				headers.append('x-access-token', localStorage.getItem('adminToken'));

				let formData = new FormData(e.target);	
				formData.append('page_content',page_content);
				formData.append('status',1); 				
				await fetch(API.PAGE,{		
					method: 'POST',  // *GET, POST, PUT, DELETE, etc.
					headers: headers,
					body: formData 			
				});	
				
				Swal.fire({
					icon: 'success',
					title: 'Congrats!',
					text: 'The information was added successfully.',
				})         
			}			
			set_new_record(true)			
		}			
	}
    useEffect( ()=>{	
		if(pageID){	
			fetchData(pageID);
		}	
		get_imagesize1()

	},[pageID])	

	if(new_record){			
		return <Redirect  to='/page' />			
	}	

  return (
	<>	
		<Meta metaData={metaData} />
		<Helmet defer={false}>
		</Helmet>	    
				
		<div className="pagetitle">
		<h1>{metaData.meta_title}</h1>
		<nav>
		<ol className="breadcrumb">
		<li className="breadcrumb-item"><Link to="/">Home</Link></li>
		<li className="breadcrumb-item"><Link to="/page">Page</Link></li>
		<li className="breadcrumb-item active">{metaData.meta_title}</li>
		</ol>
		</nav>
		</div>	

		<section className="section">
			<div className="row"> 
			<div className="col-lg-8">
				<div className="card">
				<div className="card-body">	
					<form className="row g-3" name="pageForm" id="pageForm" method="post" encType="multipart/form-data" onSubmit={handleSubmit}>
					<div className="col-12 pt-3">
						<label htmlFor="page_title" className="form-label">Page Title</label>
						<input type="text" className="form-control" id="page_title" name="page_title" value={page_title} 
						onChange={ (e) => { 
							set_page_title(e.target.value)
							setSeoUrl(e)
						}} 
						/>
						{error.page_title && 
							<div className="error-msg">{error.page_title}</div>    
						}   
					</div>
					<div className="col-12">
						<label htmlFor="url" className="form-label">SEO URL</label>
						<input type="text" className="form-control" id="url" name="url" value={url} onChange={(e)=>set_url(e.target.value)} />
						{error.url && 
							<div className="error-msg">{error.url}</div>    
						}   
					</div>

					<div className="col-12">
						<label htmlFor="inputEmail4" className="form-label">Banner Image</label>
						<br />
						<SingleImageUpload						
						data={{								
							'input_file_name'	:'banner_image',	
							'width'				:parseInt(imagesize1.width),		
							'height'			:parseInt(imagesize1.height),									
							'upload_folder_name':'cms',
							'allowedExtensions' :'jpg, jpeg, png, gif', // 'doc, docx, xls, xlsx'
							'maxFileSize'		:6, //1MB														
							'table'		        :'page',
							'table_id'		    :'pageID',
							'table_id_value'	:pageID,
							'error'	            :banner_image_error,
							'uploadedFile'	    :banner_image
						}}
						onChange={ (value,error)=>{ 
							set_banner_image(value) 
							set_banner_image_error(error) 								
						}}	
						/>
					</div>

					<div className="col-12">
						<label htmlFor="banner_text_1" className="form-label">Banner Text 1</label>
						<textarea className="form-control" id="banner_text_1" name="banner_text_1" value={banner_text_1} onChange={(e)=>set_banner_text_1(e.target.value)} />
					</div>
					<div className="col-12">
						<label htmlFor="banner_text_2" className="form-label">Banner Text 2</label>
						<textarea className="form-control" id="banner_text_2" name="banner_text_2" value={banner_text_2} onChange={(e)=>set_banner_text_2(e.target.value)}  />
					</div>
					<div className="col-12">
					   <label htmlFor="page_content" className="form-label">Page Content</label>	                       
					   <Editor	
					        id="editor1"						
							onInit={(evt, editor) => editor1_Ref.current = editor}
							initialValue={page_content}
							init={editor_config}

							onEditorChange={(e)=>{
								//console.log(editor1_Ref.current.getContent());
								//set_page_content(editor1_Ref.current.getContent())								
							}}	
							
							onSubmit={(e)=>{
								//console.log(editor1_Ref.current.getContent());
								set_page_content(editor1_Ref.current.getContent())								
							}}

						/>
						{error.page_content && 
							<div className="error-msg">{error.page_content}</div>    
						}  						
							
					</div>								

					<div className="col-12">
						<label htmlFor="meta_title" className="form-label">Meta Title</label>
						<textarea className="form-control" id="meta_title" name="meta_title" value={meta_title} onChange={(e)=>set_meta_title(e.target.value)} />
					</div>
					<div className="col-12">
						<label htmlFor="meta_keyword" className="form-label">Meta Keyword</label>
						<textarea className="form-control" id="meta_keyword" name="meta_keyword" value={meta_keyword} onChange={(e)=>set_meta_keyword(e.target.value)} />
					</div>
					<div className="col-12">
						<label htmlFor="meta_description" className="form-label">Meta Description</label>
						<textarea className="form-control" id="meta_description" name="meta_description" value={meta_description} onChange={(e)=>set_meta_description(e.target.value)} />
					</div>

					<div className="col-12">
						<label htmlFor="inputAddress" className="form-label"></label>
						<button type="submit" className="btn btn-primary">Submit</button>
					</div>					
					</form>
				</div>
				</div>
			</div>
			</div>
		</section>	

	</>
    );  
}

export default Page_form;