
import React, { Component } from "react";
import {Helmet} from "react-helmet";
import Favicon from '../../favicon.ico';

class Meta extends Component {
  /*constructor(props){
	super(props)
  }
  */
  render() {
    return (
		<>
		    <Helmet defer={false}>			
			<title>{this.props.metaData.meta_title}</title>			
			<meta name="description" content={this.props.metaData.meta_description} />
			<meta name="keywords" content={this.props.metaData.meta_keywords} />
			<link rel="shortcut icon" href={Favicon} />	
			</Helmet>
		</>     
    );
  }
}
export default Meta;