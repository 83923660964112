
const editor_config = {
	height: 250,
	menubar: false,
	branding: false,
	statusbar: false,
	plugins: [
		'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
		'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
		'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
	],
	toolbar: 'code | undo redo | blocks | ' +
		'bold italic forecolor | alignleft aligncenter ' +
		'alignright alignjustify | bullist numlist outdent indent | ' 
}
export default editor_config;


