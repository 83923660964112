import React, { useState, useEffect } from 'react';

let ProfileList = (props)=>{

    const [admin, setAdmin] = useState('')  
     	
	useEffect(() => {
		setAdmin(props.admin)		
	},[props])

    return(
        <>
            <h5 className="card-title">About</h5>
            <p className="small fst-italic">{admin.about_me}</p>

            <h5 className="card-title">Profile Details</h5>

            <div className="row">
            <div className="col-lg-3 col-md-4 label ">Full Name</div>
            <div className="col-lg-9 col-md-8">{admin.name}</div>
            </div>

            <div className="row">
            <div className="col-lg-3 col-md-4 label">Job</div>
            <div className="col-lg-9 col-md-8">{admin.user_type}</div>
            </div>					

            <div className="row">
            <div className="col-lg-3 col-md-4 label">Address</div>
            <div className="col-lg-9 col-md-8">{admin.address}</div>
            </div>

            <div className="row">
            <div className="col-lg-3 col-md-4 label">Phone</div>
            <div className="col-lg-9 col-md-8">{admin.phone_number}</div>
            </div>

            <div className="row">
            <div className="col-lg-3 col-md-4 label">Email</div>
            <div className="col-lg-9 col-md-8">{admin.email}</div>
            </div>
            
        </>
    )
}
export default ProfileList;