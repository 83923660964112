


//var SERVER_URL   = 'http://localhost:9001/';
var SERVER_URL     = 'http://reactapi.ajtek.in/';
var ADMIN_API_URL  = SERVER_URL+'api_admin/';
//var USER_API_URL = 'http://localhost:9000/api/'; 

const API = {	
	API_TOKEN   			: '123456',
	//=== URL
	SERVER_URL				: SERVER_URL,
	FILE_UPLOAD_URL 		: SERVER_URL+'assets/upload/',	

	//=== ADMIN API
	LOGIN			    	: ADMIN_API_URL+'login',
	FORGOT_PASSWORD     	: ADMIN_API_URL+'forgot-password',
	GET_ME	    	    	: ADMIN_API_URL+'me',
	EDIT_ME             	: ADMIN_API_URL+'edit-me',
	EDIT_PASSWORD           : ADMIN_API_URL+'edit-password',
	
	GET_TABLE_ROW           : ADMIN_API_URL+'get-table-row',	
	SITE_OPTION	    		: ADMIN_API_URL+'site-option',
	TABLE_TOTAL     		: ADMIN_API_URL+'get-table-total',	
	UPLOAD_IMAGE    		: ADMIN_API_URL+'upload-image',
	REMOVE_UPLOADED_FILE 	: ADMIN_API_URL+'remove-uploaded-file',

	GET_GENERAL_SETTING 	: ADMIN_API_URL+'get-general-settings',
	GET_GENERAL_SETTING_ROW : ADMIN_API_URL+'get-general-settings-row',
	EDIT_GENERAL_SETTING 	: ADMIN_API_URL+'edit-general-settings',

	PAGE 				    : ADMIN_API_URL+'page',		
	IMAGESIZE 			    : ADMIN_API_URL+'imagesize',
	BLOCK 				    : ADMIN_API_URL+'block',	

};
export default API;