// https://redux.js.org/tutorials/fundamentals/part-6-async-logic
// pagination : https://www.npmjs.com/package/react-paginate
// https://medium.com/how-to-react/create-pagination-in-reactjs-e4326c1b9855
// https://www.youtube.com/watch?v=IYCa1F-OWmk

import React, { Component } from 'react';
import {Helmet} from "react-helmet";
import Loader from "react-loader-spinner";
import { Link } from 'react-router-dom';
import Parser from 'html-react-parser';

import Pagination from "../../common/pagination/Pagination.js";
import Meta from '../../common/Meta';
import API from '../../../config/Api';
import APIModal from './child/SettingModal';

import './General_settings.css';

class general_settings extends Component {
  constructor(props) {
    super(props)

	const { location } = this.props;
	const query        = new URLSearchParams(location.search); 

    let page_number    = 1;
	if(query.get('page_number')){
		page_number = parseInt(query.get('page_number')) // use parseInt for integer browser text
	}	

	let option_display    = "";
	if(query.get('option_display')){
		option_display = query.get('option_display') // use parseInt for integer browser text
	}
	
	let option_value    = "";
	if(query.get('option_value')){
		option_value = query.get('option_value') // use parseInt for integer browser text
	}	
	
	this.state   = {
		metaData:{
			meta_title			: 'General Setting',
			meta_description	: '',
			meta_keywords		: '',
		},	
		option_id:"",
		show_modal:false,		
		error: null,		
		loader: true,
		option_display:option_display,
		option_value:option_value,
		page_number: page_number,	
		total:0,
		records:"",		
		item_per_page:10,		
		sl_no:0		
	}   	
	this.handleChange 		= this.handleChange.bind(this);	
	this.handleSubmit 		= this.handleSubmit.bind(this);
	this.handlePaginate  	= this.handlePaginate.bind(this);	
  }    
  async getRecords(page_number){	
	let headers = new Headers();
	headers.append('Content-Type', 'application/json');	
	headers.append('x-access-token', localStorage.getItem('adminToken'));			
	const response = await fetch(API.GET_GENERAL_SETTING,{		
		method: 'POST',  
		headers: headers,
		body: JSON.stringify({
			'option_display':this.state.option_display,
			'option_value':this.state.option_value,
			'item_per_page':this.state.item_per_page,
			'page_number':page_number,
		}) 
	})
	const result = await response.json();
	console.log(result)
	if(result){
		this.setState({
			loader:false,
			total:result.option.total,
			records:result.data,			
			sl_no:result.option.sl_no,	
			page_number:page_number,	
		});			
	}					   
  }    
  async componentDidMount(){ 	
	this.getRecords(this.state.page_number);	
  }  
  async handleSubmit(e){
	e.preventDefault(); 

    //== set browser url
	const url = new URL(window.location);
	url.search="";
	if(this.state.option_display){
		url.searchParams.set('option_display', this.state.option_display);
	}
	if(this.state.option_value){
		url.searchParams.set('option_value', this.state.option_value);
	}	
	window.history.pushState({},'', url);

    //== get record
	this.getRecords(1);		
  }
  async handleChange(e){		
	e.preventDefault();	
	const { name, value } = e.target;
	this.setState({
		[name]:value			
	});
  }  
  async handlePaginate(pgNo){		
	this.getRecords(pgNo);   
    // add page_number on browser url 
	// https://www.codegrepper.com/code-examples/javascript/how+to+pass+data+in+history.push+in+react+js
    /*
	this.props.history.push({
		pathname: '/API',
		search: '?page_number='+pgNo,
		state: { detail: '' }
	})
	*/
	
	//== set browser url
	const url = new URL(window.location);	
	if(pgNo){
		url.searchParams.set('page_number', pgNo);
	}
	window.history.pushState({},'', url);
    //======
  }
  
  render() {	
	var pageCount   = Math.ceil(this.state.total/this.state.item_per_page);
    var records     = this.state.records;
	var sl_no       = this.state.sl_no;
	
    return (
	<>	
		<Meta metaData={this.state.metaData} />		
		<Helmet defer={false}>		
		</Helmet>	    
				
		<div className="pagetitle">
			<h1>General Setting</h1>
			<nav>
			<ol className="breadcrumb">
				<li className="breadcrumb-item"><Link to="/">Home</Link></li>				
				<li className="breadcrumb-item active">General Setting</li>
			</ol>
			</nav>
		</div>

		{ this.state.loader ? 
		
		<div className="loader">
		<Loader
		type="Oval"
		color="#ccc"
		height={50}
		width={50}
		timeout={300} //3 secs
		/>
		</div> 		
		: 
		<section className="section">
		<div className="row">
			<div className="col-lg-12">
				<div className="card">
				{/*
				<div className="card-header">
				<div className='pull-right'>				
				<Link to="#" className="p-1"><button type="button" className="btn btn-success">Export</button></Link>				
				<Link to="#" className="p-1"><button type="button" className="btn btn-primary">+ Add New</button></Link>
				</div>
				</div>
				*/}
				<form id="frmFilter" name="frmFilter" method="post" encType="multipart/form-data" onSubmit={this.handleSubmit}>				
				<div className="card-body table-responsive">					         
				<table className="table table-hover">
					<thead>
					<tr>
						<th scope="col" width="5%">SL</th>
						<th scope="col">Code</th>
						<th scope="col">Option name</th>
						<th scope="col">Option value</th>
						<th scope="col" width="10%" className="text-center">Action</th>						
					</tr>
					<tr>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
						<td><input className="form-control" type="text" 
						name="option_display" 
						value={this.state.option_display}  
						onChange={this.handleChange} /></td>

						<td><input className="form-control" type="text" 
						name="option_value" 
						value={this.state.option_value}   
						onChange={this.handleChange} /></td>

						<td className="text-center">
						<button type="submit" name="OkFilter" id="OkFilter" className="btn  btn-primary">Filter</button>
						</td>
					</tr>
					</thead>
					<tbody>
					{records &&
						records.map((doc,index) => (  
						<tr key={index+1}>
							<td>{++sl_no}</td>
							<td style={{fontSize:'11px'}}>{doc.option_name}</td>
							<td>{doc.option_display}</td>
							<td id={`option_value-${doc._id}`}>
							{doc.option_value ? Parser(doc.option_value) : '' }						
							</td>
							<td className="text-center">
								{/* <button type="button" className="btn btn-primary btn-sm" title='View'>
								<i className="bi bi-eye"></i>
								</button> */}

								<button type="button" className="btn btn-info btn-sm" title='Edit' data-bs-toggle="modal" data-bs-target="#modal-APIModal"
								onClick={() => this.setState({
									'option_id': doc._id,
									'show_modal': true
								})}>
								<i className="bi bi-pencil-square"></i>
								</button>

								{/* <button type="button" className="btn btn-danger btn-sm" title='Delete'>
								<i className="bi bi-trash"></i>
								</button> */}
							</td>
						</tr> 						
					))}  									           
					</tbody>
				</table>  
				{records && records.length <1 &&						
					<div className='text-center'>No record Found!</div>						
  				} 		
				</div>
				</form>
				<div className="card-body table-responsive">	
				
				{pageCount>1 && 
					<Pagination data={{
					'total'			:this.state.total,
					'item_per_page'	:this.state.item_per_page,
					'page_number'	:this.state.page_number,
					'handlePaginate':this.handlePaginate						
					}}/>
				}				
                </div>
			</div>    
			</div>
		</div>
		<APIModal data={{								
		'option_id'	:this.state.option_id,
		'show_modal':this.state.show_modal
		}} />
		</section>
		
  		}
	</>
    );
  }
}

export default general_settings;