// https://www.educative.io/edpresso/file-upload-in-react
import React, {useState,useEffect } from 'react';
import Swal from 'sweetalert2'
import API from '../../../config/Api'; 
import './SingleImageUpload.css';

let SingleImageUpload = (props)=>{
	
	let input_file_name     = props.data.input_file_name;
	let width 	  			= props.data.width;
	let height    			= props.data.height;
	let upload_folder_name  = props.data.upload_folder_name;

	let allowedExtensions   = props.data.allowedExtensions; 
	let allowedExtensionsArr= allowedExtensions.split(",").map(item => item.trim()); 

	let maxFileSize    		= props.data.maxFileSize;
    let maxFileSizeInBytes	= parseInt(maxFileSize*1048576);

	let table 			    = props.data.table;
	let table_id 			= props.data.table_id;
    let table_id_value 		= props.data.table_id_value;	

	let upload_url          = API.FILE_UPLOAD_URL+upload_folder_name+'/thumb/';
	
    let [uploadedFile,SetUploadedFile] = useState(null);
	let [error,SetError]               = useState(null); 
	let [currentFile,SetCurrentFile]   = useState(null);    
	
	const changeHandler = async (event) => {		

        let file           = event.target.files[0];
		let file_size      = file.size
		let file_extension = file.name.split('.').pop()	
		
		if( file_size > maxFileSizeInBytes || allowedExtensionsArr.indexOf(file_extension) < 0){
			let error_txt = 'Please Upload only '+allowedExtensions+' image of maximum '+maxFileSize+' MB file limit'								
			SetError(error_txt);	
			props.onChange(uploadedFile,error_txt)	
		}
        else{
  			var file_url    = URL.createObjectURL(file)
			SetCurrentFile(file_url);
			props.onChange(uploadedFile,'')
		}		
			
	} 		
	let confirmDelete = async () => {	
        Swal.fire({
			icon: 'warning',
			title: 'Are you sure?',
			text: "You want to delete this file!",			
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Yes, delete it!'
		  }).then((result) => {
			if (result.isConfirmed) {			  
				deleteFile()				
			}
		})
	}
    let deleteFile = async () => {			
        let headers = new Headers();	 
		headers.append('Content-Type', 'application/json');	   
		headers.append('x-access-token', localStorage.getItem('adminToken'));

		let response = await fetch(API.REMOVE_UPLOADED_FILE,{		
			method: 'POST', 
			headers: headers,			
			body: JSON.stringify({ 
				'file':uploadedFile,
				'input_file_name':input_file_name,
				'upload_folder_name':upload_folder_name,
				'table':table,
				'table_id':table_id,
				'table_id_value':table_id_value,				
			})	
		});
		const result = await response.json();		
		console.log(result)	

		SetUploadedFile(null); 
		SetCurrentFile(null); 			
		//props.onChange(null) //<-- update parent states
		props.onChange(null,props.data.error)
		Swal.fire({
			icon: 'success',
			title: 'Deleted!',
			text: "Your file has been deleted."
		})
		
	}
	useEffect( () => {	
		if(props.data.uploadedFile ){			
			//setTimeout(() => {
				SetUploadedFile(props.data.uploadedFile);
			    SetCurrentFile(null); 
			//}, 500);
		}
		else{
			SetUploadedFile(null);			
		}

		if(props.data.error && !uploadedFile && !currentFile ){			
			SetError(props.data.error);			
		}
		else{
			SetError(null);			
		}			
				
	},[props,uploadedFile,currentFile]); 

	
	return(         
		<>			
			{uploadedFile && 
				<>				
				<img className='uploaded-img' src={upload_url + uploadedFile} alt="" />					
				<img src="/assets/img/icon/delete.png" className="delete-btn" onClick={confirmDelete} alt='' /> 
				<input type="hidden" id={input_file_name} name={input_file_name} value={uploadedFile} />     				
				</>
			}  
			{!uploadedFile && 
				<>	
				{currentFile && 
				<div style={{'paddingBottom':'5px'}}><img className='uploaded-img' src={currentFile} alt='' /></div>	
				}
				<input type="file" id={input_file_name} name={input_file_name} onChange={changeHandler} />							
				<div className="help-block">Image size : {width}X{height} Pixel</div>
				</>
			}  	
			{error &&
				<div className="error-msg">{error}</div>    
			}  					
		</>
	)
   
}
export default SingleImageUpload;
