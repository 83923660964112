

import { Route } from 'react-router-dom';
import AdminLayout from '../layouts/AdminLayout';

function RouteWithSubRoutes(route) {	
  if(route.path==='/login' || route.path==='/forgot-password'){
	  return (
		<Route		  
		  path={route.path}
		  render={props => (			
			<route.component {...props} />
		  )}
		/>
	  );
  }  
  else{
	  return (
		<Route		 
		  path={route.path}
		  render={props => (
			<AdminLayout {...props}  />
		  )}
		/>
	  );
  }
  
}
export default RouteWithSubRoutes;