
import React, { Component } from 'react'; 
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';

import Routes from './routes/Routes';
import RouteWithSubRoutes from './routes/RouteWithSubRoutes';
import NotFound from './components/pages/not_found/NotFound';

class App extends Component {	
  render() {
    return (
		<Router>
		  <Switch>			
				{/*admin routing*/}
				{Routes.map((route, i) => (
					<RouteWithSubRoutes key={i} {...route} />
				))}	

			  	<Redirect from="/123" to='' />
			  
			   {/*not found routing*/}
			   <Route component={NotFound} />
		  </Switch>
		</Router>
    );
  }
}
export default App;