import React, { Component } from 'react';

import {Helmet} from "react-helmet";
import { Redirect,Link } from 'react-router-dom';
import Meta from '../../common/Meta';
import API from '../../../config/Api';
import validation from '../../../config/Validation';
import './Forgot_password.css';

class Forgot_password extends Component {  

	constructor(props) {
		super(props);		
		let email  = '';	
		this.state = {
			metaData:{
				meta_title			: 'Forgot password',
				meta_description	: '',
				meta_keywords		: '',
			},				
			loggedIn    : false,
			email		: email,			
			errors: {
			  email	: ''
			},
			common_error: '',
			success_msg :''
		};
		
		this.handleChange       = this.handleChange.bind(this);	
		this.handleSubmit       = this.handleSubmit.bind(this);
		
	} 
	async componentDidMount() { 
		let headers = new Headers();
		headers.append('Content-Type', 'application/json');	
		headers.append('x-access-token', localStorage.getItem('adminToken'));	
		const response = await fetch(API.GET_ME,{		
		  method: 'POST',  
		  headers: headers,
		  body: ''
		});
		const result = await response.json(); 
		if(result.status===true){
			this.setState({			
			  loggedIn: true
			});
		}  
	}    
	handleChange(e){		
		e.preventDefault();
		const { name, value } = e.target;
		let errors = this.state.errors;	    
		
		switch (name) {
		  case 'email': 
			if(!validation.validateEmail(value)){ 
				errors.email = 'Email is not valid!';
			}
			else{
				errors.email = '';
			}
			break;
		  default:
			break; 
		}		
		this.setState({
			errors, [name]: value,
			common_error  : '',
			success_msg	  : ''
		});
	}	
    validateForm(){
		const {email}    = this.state; 
		let errors       = this.state.errors;  
		let isValid      = true;   
		
		if(!validation.validateEmail(email)) {
			isValid 		       = false;
			errors.email           = 'Email is not valid!';
		}	
		this.setState({
			errors : errors			
		});	
		return isValid;		 
	}
    async handleSubmit(e){
		e.preventDefault();        
		if(this.validateForm()){		
			
			let headers = new Headers();
			headers.append('Content-Type', 'application/json');	
			
			const response = await fetch(API.FORGOT_PASSWORD,{		
				method: 'POST',  // *GET, POST, PUT, DELETE, etc.
				headers: headers,
				body: JSON.stringify(this.state) 
			});
			const result = await response.json();			
			if(result.status){	
				this.setState({
					success_msg : result.message
				})						
			}
			else{
				this.setState({
					common_error : result.message
				})	
			}			
		}			
	}
	
    render() {
	if(this.state.loggedIn===true){	
		return <Redirect exact  to='/' />
	}
	const {errors} 		= this.state;	
		
    return ( 
	<>
		<Meta metaData={this.state.metaData} />
		<Helmet defer={false}>
		</Helmet>
		
		<main>
		<div className="container">
		<section className="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
		<div className="container">
		<div className="row justify-content-center">
		<div className="col-lg-4 col-md-6 d-flex flex-column align-items-center justify-content-center">
		<div className="d-flex justify-content-center py-4">
		<Link to="/forgot-password" className="logo d-flex align-items-center w-auto">
			<img src="/assets/img/logo.png" alt="" />			
		</Link>
		</div>
		<div className="card mb-3">
		<div className="card-body">
			<div className="pt-4 pb-2">
			<h5 className="card-title text-center pb-0 fs-4">Forgot Password</h5>
			<p className="text-center small">You forgot your password? Here you can easily retrieve a new password.</p>
			</div>
			{this.state.common_error &&
            	<div className="p-2 pl-3 mb-3 bg-danger text-white">{this.state.common_error}</div>   
			}  
			{this.state.success_msg &&
				<div className="p-2 pl-3 mb-3 bg-success text-white">{this.state.success_msg}</div>   
			} 
			<form className="row g-3 needs-validation" method="post" onSubmit={this.handleSubmit}>
			
			<div className="col-12">
			<div className={`input-group ${errors.email ? 'error' : ''}`}>
			<input 
			type="text" 
			className="form-control" 
			placeholder="Email" 
			id="email"
			name="email" 
			value={this.state.email} 
			onChange={this.handleChange}
			autoComplete="off"/>
			</div>				
			{errors.email && 
			<div className="error-msg">{errors.email}</div>    
			}  	
			</div>

			<div className="col-12">
			<button className="btn btn-primary w-100" type="submit">Request new password</button>
			</div>
			<div className="col-12">
			<p className="small mb-0"> <Link to="/login">Login</Link> </p>			
			</div>
			</form>

		</div>
		</div>
		<div className="credits">
		Designed by <a href="https://bootstrapmade.com/">BootstrapMade</a>
		</div>
		</div>
		</div>
		</div>
		</section>
		</div>
		</main>
		
	</>
    );
  }
}

export default Forgot_password;

