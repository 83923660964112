import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2'
import API from '../../../../config/Api';

let APIModal = (props)=>{
    
    let option_id  = props.data.option_id;
    
    let [rowData, setRowData]       = useState("");    
    let [formValue, setFormValue]   = useState({
        option_value: '',
        //option_display: ''
    })

    const handleChange = (event) => {
        let value = event.target.value;
        let name  = event.target.name;      
        setFormValue((prevalue) => {
          return {
            ...prevalue,   // Spread Operator               
            [name]: value
          }
        })
    }

    const handleSubmit = async (e)=>{
		e.preventDefault();
      
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');	
        headers.append('x-access-token', localStorage.getItem('adminToken'));        
        
        let form_DATA = new FormData(e.target); 
        let form_obj  = {};	               
        for (let [key, value] of form_DATA.entries()) {				
            form_obj[key] = value;	
        }       
        form_obj['_id'] = option_id;	

        await fetch(API.EDIT_GENERAL_SETTING,{		
            method: 'POST',  
            headers: headers,
            body: JSON.stringify(form_obj) 			
        });        

        let closeBtn = document.querySelector(".btn-close"); 
        closeBtn.click()       
        
        let OkFilter = document.querySelector("#OkFilter"); 
        OkFilter.click()        
        //window.location.reload();
       
        Swal.fire({
            icon: 'success',
            title: 'Congrats!',
            text: 'The information was updated successfully.',
        }) 
    }     

    useEffect(() => {		
        const fetchData = async () => {
            let headers = new Headers();
            headers.append('Content-Type', 'application/json');	
            headers.append('x-access-token', localStorage.getItem('adminToken'));	
            const response = await fetch(API.GET_GENERAL_SETTING_ROW,{		
                method: 'POST',  
                headers: headers,
                body: JSON.stringify({
                    '_id':option_id			
                }) 
            });        
            const result = await response.json();
            if(result.status===true){	
                setRowData(result.data)  
                setFormValue({
                    option_value: result.data.option_value,
                    //option_display: result.data.option_display
                })                
            }
        };        
        fetchData()
	},[props,option_id]);
    return(
        <> 
        <div className="modal fade" id="modal-APIModal">
        <div className="modal-dialog modal-lg">
            <form name="APIModalForm" id="APIModalForm" method="post" encType="multipart/form-data" onSubmit={handleSubmit}>
            <div className="modal-content">
            <div className="modal-header">
                <h5 className="modal-title">
                    {rowData.option_display}                    
                </h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
            <textarea className="form-control" id="option_value" name="option_value" style={{height:'150px'}} 
			value={formValue.option_value}     
            onChange={handleChange}       
			/>             
            </div>
            <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                <button type="submit" className="btn btn-primary">Save</button>
            </div>
            </div>
            </form>
        </div>
        </div>
        </>
    )
}
export default APIModal;