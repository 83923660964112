import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

class Logout extends Component {
	constructor(props) {
		super(props);
		localStorage.removeItem('adminToken');
		localStorage.removeItem('admin');
		localStorage.removeItem('admin_id');			
	}	
   render() {
	if(!localStorage.getItem('adminToken')){	
		return <Redirect exact  to='/login' />		
	}
    return (
		<>		
		</>
    );
  }
}

export default Logout;