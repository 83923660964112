import React, { useState, useEffect } from 'react';
import {Helmet} from "react-helmet";
import Loader from "react-loader-spinner";
import { Link } from 'react-router-dom';
import Parser from 'html-react-parser';
import Swal from 'sweetalert2'
import all_function from '../../../config/All_function';
import Pagination from "../../common/pagination/Pagination";
import Meta from '../../common/Meta';
import API from '../../../config/Api';
import './Page.css';

let  Page = (props)=> {    
    
	const queryParams  = new URLSearchParams(window.location.search) 

    let __page_number = 1;
	if(queryParams.get('page_number')){
		// use parseInt for integer browser text
		__page_number = parseInt(queryParams.get('page_number')) 
	}
	let __page_title = "";
	if(queryParams.get('page_title')){		
		__page_title = queryParams.get('page_title') 
	}
	let __url = "";
	if(queryParams.get('url')){	
		__url = queryParams.get('url') 
	}	
	let __status = "";
	if(queryParams.get('status')){	
		__status = queryParams.get('status') 
	}      

	const item_per_page = 2	
	const metaData = {
		meta_title			: 'Page',
		meta_description	: '',
		meta_keywords		: '',
	} 
		
	const [loader,set_loader] 				= useState(true)
	const [page_number,set_page_number]   	= useState(__page_number)
	const [page_title,set_page_title] 		= useState(__page_title)
	const [url,set_url] 					= useState(__url)
	const [status,set_status] 				= useState(__status)
	const [total,set_total] 				= useState(0)
	const [records,set_records] 			= useState(null)	
	let [sl_no,set_sl_no] 			    	= useState(0)	
     
	const fetchData = async (page_number)=> {	
		let headers = new Headers();
		headers.append('Content-Type', 'application/json');	
		headers.append('x-access-token', localStorage.getItem('adminToken'));	
		
		let params = {
			'page_title':page_title,
			'url':url,
			'status':status,
			'item_per_page':item_per_page,
			'page_number':page_number
		}
		let querystring = all_function.objToQuerystring(params)
		const response  = await fetch(API.PAGE+'?'+querystring,{		
			method: 'GET',  
			headers: headers			
		})
		const result = await response.json();	
		if(result){		
			set_loader(false)
			set_total(result.option.total)
			set_records(result.data)
			set_sl_no(result.option.sl_no)
			set_page_number(page_number)		
		}					   
	}    
	const handleCheckall = (e)=>{
		let inputs = document.querySelectorAll('.selected-chk');  	
		if(e.target.checked){			
			for (var i = 0; i < inputs.length; i++) {   
				inputs[i].checked = true;   
			}   
		} 
		else{
			for (var j = 0; j < inputs.length; j++) {   
				inputs[j].checked = false;   
			}   
		}	
	}  
  
	const setBrowserUrl = (pgNo)=>{	
		const location = new URL(window.location);
		location.search="";
		if(page_title){
			location.searchParams.set('page_title', page_title);
		}
		if(url){
			location.searchParams.set('url',url);
		}	
		if(status){
			location.searchParams.set('status', status);
		}	
		if(pgNo){
			location.searchParams.set('page_number', pgNo);
		}
		window.history.pushState({},'', location);
	}
	const handleSubmit = (e)=>{
		e.preventDefault();	
		setBrowserUrl(0)	
		fetchData(1)	
	}
	
	const handlePaginate = (pgNo)=>{
		setBrowserUrl(pgNo)		
		fetchData(pgNo); 		
	}
	const confirmDelete = async (pageID)=>{	
		Swal.fire({
			icon: 'warning',
			title: 'Are you sure?',
			text: "You want to delete this item!",			
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Yes, delete it!'
			}).then( async (result) => {
			if (result.isConfirmed) {				
				let headers = new Headers();
				headers.append('Content-Type', 'application/json');		    
				headers.append('x-access-token', localStorage.getItem('adminToken'));		
				await fetch(API.PAGE+'/'+pageID,{		
					method: 'DELETE', 
					headers: headers
				});			
				setBrowserUrl(0)	          
				fetchData(1)
				Swal.fire({
					icon: 'success',
					title: 'Deleted!',
					text: "Selected item has been deleted."
				})		
			}
		})	
  	} 
	const applyToSelected = (action)=>{
		let selected_ID = [];
		let inputs      = document.querySelectorAll('.selected-chk:checked');  	
		selected_ID     = Array.from(inputs).map(x => x.value)
	
		if(action && inputs.length){
			Swal.fire({
				icon: 'warning',
				title: 'Are you sure?',
				text: "You want to perform selected action",			
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Yes, do it!'
			}).then( async (result) => {
				if (result.isConfirmed) {
					
					let headers = new Headers();
					headers.append('Content-Type', 'application/json');		    
					headers.append('x-access-token', localStorage.getItem('adminToken'));
					
					if(action==='Enable' || action==='Disable'){
						await fetch(API.PAGE,{		
							method: 'PUT', 
							headers: headers,			
							body: JSON.stringify({
								'pageID':selected_ID,	
								'action':action				
							})	
						});					
						setBrowserUrl(0)				
						fetchData(1)					
						Swal.fire({
							icon: 'success',
							title: 'Updated!',
							text: 'Selected item has been updated successfully.'
						})	
					}
					else if(action==='Delete'){
						await fetch(API.PAGE,{		
							method: 'DELETE', 
							headers: headers,			
							body: JSON.stringify({
								'pageID':selected_ID									
							})	
						});					
						setBrowserUrl(0)					
						fetchData(1)					
						Swal.fire({
							icon: 'success',
							title: 'Updated!',
							text: 'Selected item has been Deleted successfully.'
						})	
					}
				}
			})
		}	
  	}     
	useEffect( ()=>{			
		if(page_number){	
			fetchData(page_number);
		}
	},[page_number])	

	let pageCount   = Math.ceil(total/item_per_page);  

    return (
	<>	
		<Meta metaData={metaData} />		
		<Helmet defer={false}>	
		<script>
		
		</script>
		</Helmet>	    
				
		<div className="pagetitle">
			<h1>Page</h1>
			<nav>
			<ol className="breadcrumb">
				<li className="breadcrumb-item"><Link to="/">Home</Link></li>				
				<li className="breadcrumb-item active">Page</li>
			</ol>
			</nav>
		</div>

		{ loader ? 
		
		<div className="loader">
		<Loader
		type="Oval"
		color="#ccc"
		height={50}
		width={50}
		timeout={100} //3 secs
		/>
		</div> 		
		: 
		<section className="section">
		<div className="row">
			<div className="col-lg-12">
				<div className="card">
				
				<div className="card-header">
				<div className='pull-right'> 
				<Link to="add-page" className="p-1"><button type="button" className="btn btn-primary">+ Add New</button></Link>
				</div>
				</div>
				
				<form id="frmFilter" name="frmFilter" method="post" encType="multipart/form-data" onSubmit={handleSubmit}>				
				<div className="card-body table-responsive">					         
				<table className="table table-hover">
					<thead>
					<tr>
					    <th><input className="form-check-input checkall" type="checkbox" onClick={handleCheckall} /></th>
						<th scope="col" width="5%">SL</th>
						<th scope="col">Page Title</th>
						<th scope="col">URL</th>
						<th scope="col">Status</th>
						<th scope="col" width="10%" className="text-center">Action</th>						
					</tr>
					<tr>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
						<td>
						<input className="form-control" type="text" 
						maxLength={150}
						name="page_title" 
						value={page_title}  
						onChange={ (e)=> set_page_title(e.target.value) } />	
						</td>
						<td>
						<input className="form-control" type="text" 
						maxLength={150}
						name="url" 
						value={url}  
						onChange={ (e)=> set_url(e.target.value) } />
						</td>

						<td>
						<select className="form-select" name="status" value={status} onChange={ (e)=> set_status(e.target.value) }>
							<option value=""></option>
							<option value="0">Inactive</option>
							<option value="1">Active</option>
						</select>
						</td>

						<td className="text-center">
						<button type="submit" name="OkFilter" id="OkFilter" className="btn  btn-primary">Filter</button>
						</td>
					</tr>
					</thead>
					<tbody>
					{records &&
						records.map((doc,index) => ( 
						<tr key={index+1}>
							<td>							
							{doc.deletable===1 &&
								<input type="checkbox" className="form-check-input selected-chk" name="id[]" value={doc.pageID} />
							}
							</td>
							<td>{++sl_no}</td>
							<td>{Parser(doc.page_title)}</td>
							<td>{doc.url}</td>
							<td>
								{doc.status===0 &&
									<span className="badge rounded-pill bg-danger">InActive</span>
								}
								{doc.status===1 &&
									<span className="badge rounded-pill bg-success">Active</span>
								}
							</td>
							<td className="text-center">
							    
								<Link to={`edit-page/${doc.pageID}`}>
								<button type="button" className="btn btn-info btn-sm" title='Edit'>
								<i className="bi bi-pencil-square"></i>
								</button>
								</Link>

								<button type="button" className="btn btn-danger btn-sm" title='Delete' onClick={()=>confirmDelete(doc.pageID)}>
								<i className="bi bi-trash"></i>
								</button>
							</td>
						</tr> 						
                       ))}  									           
					</tbody>
				</table>
				{records && records.length>=1 &&		
				<div className="row">
					<div className="col-lg-3">
						<select name="action" id="frm_action" className="form-select">
							<option value="">Choose an action...</option>
							<option value="Delete">Delete</option>
							<option value="Enable">Enable</option>
							<option value="Disable">Disable</option>
						</select>
					</div>
					<div className="col-lg-9">
						<button 
						type="button" 
						onClick={()=>applyToSelected(document.getElementById('frm_action').value)} 
						className="btn btn-block btn-primary">Apply to selected</button>
					</div>
				</div>  
                }
				{records && records.length===0 &&						
					<div className='text-center'>No record Found!</div>						
  				} 			
				</div>
				</form>
				
				{pageCount>1 && 
				    <div className="card-body table-responsive">
					<Pagination data={{
					'total'			:total,
					'item_per_page'	:item_per_page,
					'page_number'	:page_number,
					'handlePaginate':handlePaginate						
					}}/>
					</div>
				}				
                
			</div>    
			</div>
		</div>		
		</section>
		
  		}
	</>
    );  
}

export default Page;