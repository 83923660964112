// https://redux.js.org/tutorials/fundamentals/part-6-async-logic

import React, { Component } from 'react';
import {Helmet} from "react-helmet";
import { Link } from 'react-router-dom';
import Meta from '../../common/Meta';
import API from '../../../config/Api';

class Dashboard extends Component {
  constructor(props) {
    super(props)
	this.state   = {
		metaData:{
			meta_title			: 'Dashboard',
			meta_description	: '',
			meta_keywords		: '',
		},		
		error: null,		
		table_total: '',
		loader: true
	}	    
  }    
  async componentDidMount() {  
	
	    let headers = new Headers();
		headers.append('Content-Type', 'application/json');	
		headers.append('x-access-token', localStorage.getItem('adminToken'));			
		
		const response = await fetch(API.TABLE_TOTAL,{		
			method: 'POST',  
			headers: headers,
			body: JSON.stringify({
			'option_name':'copyright_text'
		}) 
		});
		const result = await response.json();		
		this.setState({			
			table_total: result,
			loader:false			
			
		});				   
  } 
    
  render() {	

    return (
	<>	
		<Meta metaData={this.state.metaData} />
		<Helmet defer={false}>
		<script type="text/javascript">{`
		   //alert();
		`}</script>
		</Helmet>	    
				
		<div className="pagetitle">
		<h1>Dashboard</h1>
		<nav>
		<ol className="breadcrumb">
		<li className="breadcrumb-item"><Link to="/">Home</Link></li>
		<li className="breadcrumb-item active">Dashboard</li>
		</ol>
		</nav>
		</div>

		<section className="section dashboard">
		<div className="row">

		<div className="col-lg-12">
		<div className="row">

		<div className="col-xxl-3 col-md-6">
		    <Link to="page">
			<div className="card info-card sales-card">			
			<div className="card-body">
				<h5 className="card-title">Page {/*<span> | Today</span>*/}</h5>
				<div className="d-flex align-items-center">
				<div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
			    <i className="bx bxs-file"></i>
				</div>
				<div className="ps-3">
				<h6>145</h6>  
				</div>
				</div>
			</div>
			</div>
			</Link>
		</div>   

		<div className="col-xxl-3 col-md-6">
		    <Link to="block">
			<div className="card info-card sales-card">			
			<div className="card-body">
				<h5 className="card-title">Block</h5>
				<div className="d-flex align-items-center">
				<div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
			    <i className="bx bxs-file-txt"></i>
				</div>
				<div className="ps-3">
				<h6>145</h6>  
				</div>
				</div>
			</div>
			</div>
			</Link>
		</div>    


		<div className="col-xxl-3 col-md-6">
		    <Link to="banner-category">
			<div className="card info-card sales-card">			
			<div className="card-body">
				<h5 className="card-title">Banner </h5>
				<div className="d-flex align-items-center">
				<div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
			    <i className="bi bi-images"></i>
				</div>
				<div className="ps-3">
				<h6>145</h6>  
				</div>
				</div>
			</div>
			</div>
			</Link>
		</div>          


		<div className="col-xxl-3 col-md-6">
		    <Link to="testimonial">
			<div className="card info-card sales-card">			
			<div className="card-body">
				<h5 className="card-title">Testimonial</h5>
				<div className="d-flex align-items-center">
				<div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
			    <i className="bi bi-aspect-ratio-fill"></i>
				</div>
				<div className="ps-3">
				<h6>145</h6>  
				</div>
				</div>
			</div>
			</div>
			</Link>
		</div>  

		<div className="col-xxl-3 col-md-6">
		    <Link to="service">
			<div className="card info-card sales-card">			
			<div className="card-body">
				<h5 className="card-title">Service</h5>
				<div className="d-flex align-items-center">
				<div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
			    <i className="bx bxs-florist"></i>
				</div>
				<div className="ps-3">
				<h6>145</h6>  
				</div>
				</div>
			</div>
			</div>
			</Link>
		</div>  


		<div className="col-xxl-3 col-md-6">
		    <Link to="email-template">
			<div className="card info-card sales-card">			
			<div className="card-body">
				<h5 className="card-title">Email Template</h5>
				<div className="d-flex align-items-center">
				<div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
			    <i className="bi bi-envelope-fill"></i>
				</div>
				<div className="ps-3">
				<h6>145</h6>  
				</div>
				</div>
			</div>
			</div>
			</Link>
		</div>   

		<div className="col-xxl-3 col-md-6">
		    <Link to="users">
			<div className="card info-card sales-card">			
			<div className="card-body">
				<h5 className="card-title">Admin Users</h5>
				<div className="d-flex align-items-center">
				<div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
			    <i className="bx bxs-user"></i>
				</div>
				<div className="ps-3">
				<h6>145</h6>  
				</div>
				</div>
			</div>
			</div>
			</Link>
		</div>  

		<div className="col-xxl-3 col-md-6">
		    <Link to="settings">
			<div className="card info-card sales-card">			
			<div className="card-body">
				<h5 className="card-title">Settings</h5>
				<div className="d-flex align-items-center">
				<div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
			    <i className="bi bi-gear-fill"></i>
				</div>
				<div className="ps-3">
				<h6>145</h6>  
				</div>
				</div>
			</div>
			</div>
			</Link>
		</div> 


		<div className="col-xxl-3 col-md-6">
		    <Link to="faq">
			<div className="card info-card sales-card">			
			<div className="card-body">
				<h5 className="card-title">FAQ</h5>
				<div className="d-flex align-items-center">
				<div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
			    <i className="bi bi-patch-question-fill"></i>
				</div>
				<div className="ps-3">
				<h6>145</h6>  
				</div>
				</div>
			</div>
			</div>
			</Link>
		</div> 

		<div className="col-xxl-3 col-md-6">
		    <Link to="product">
			<div className="card info-card sales-card">			
			<div className="card-body">
				<h5 className="card-title">Product</h5>
				<div className="d-flex align-items-center">
				<div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
			    <i className="bi bi-bag-plus-fill"></i>
				</div>
				<div className="ps-3">
				<h6>145</h6>  
				</div>
				</div>
			</div>
			</div>
			</Link>
		</div>  

		<div className="col-xxl-3 col-md-6">
		    <Link to="imagesize">
			<div className="card info-card sales-card">			
			<div className="card-body">
				<h5 className="card-title">Image Size</h5>
				<div className="d-flex align-items-center">
				<div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
			    <i className="bi bi-image"></i>
				</div>
				<div className="ps-3">
				<h6>145</h6>  
				</div>
				</div>
			</div>
			</div>
			</Link>
		</div>    


		<div className="col-xxl-3 col-md-6">
		    <Link to="contact">
			<div className="card info-card sales-card">			
			<div className="card-body">
				<h5 className="card-title">Contact</h5>
				<div className="d-flex align-items-center">
				<div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
			    <i className="bi bi-telegram"></i>
				</div>
				<div className="ps-3">
				<h6>145</h6>  
				</div>
				</div>
			</div>
			</div>
			</Link>
		</div>                                                                            

		</div>
		</div>
		</div>
		</section>

	</>
    );
  }
}

export default Dashboard;