
import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import Header from '../components/common/header/Header';
import Sidebar from '../components/common/sidebar/Sidebar';
import Footer from '../components/common/footer/Footer';

import routes from '../routes/Routes';
import API from '../config/Api';

const getRoutes = (routes) => {
    return routes.map((prop, key) => {
       return (
          <Route
            path={prop.path}
			      exact={true}
            render={(props) => <prop.component {...props} />}
            key={key}
          />
        );
    });
};

class AdminLayout extends Component {  
  
  constructor(props) {
    super(props)
    this.state  = {     
      loggedIn: true
    }	    
  }

  async componentDidMount() { 
    let headers = new Headers();
    headers.append('Content-Type', 'application/json');	
    headers.append('x-access-token', localStorage.getItem('adminToken'));			

    const response = await fetch(API.GET_ME,{		
      method: 'POST',  
      headers: headers,
      body: ''
    });
    const result = await response.json(); 
    if(result.status===false){
        this.setState({			
          loggedIn: false
        });
    }  
  } 
  componentWillUnmount() {
		// fix Warning: Can't perform a React state update on an unmounted component
		this.setState = (state,callback)=>{
			return;
		};
	}
  render() {    
    if(this.state.loggedIn===false){      
      return <Redirect exact  to='/login' />	
    }
    return (
    <>      
      <Header/>
      <Sidebar/>     
      <main id="main" className="main">
      <Switch>{getRoutes(routes)}</Switch>
      </main>
      <Footer/>      
    </>
    );
  }
}

export default AdminLayout;

