import React, {useState,useEffect } from 'react';
import { Link } from 'react-router-dom';
import API from '../../../config/Api';
import all_function from '../../../config/All_function';

//import SearchBar from './child/SearchBar';
//import Notifications from './child/Notifications';
//import Messages from './child/Messages';

let Header = (props) =>{	

	let [admin,SetAdmin]            = useState("");
	let [is_sidebar,SetSidebar]     = useState(true); 	
    
	const toggleSidebar = (is_sidebar)=> {		
		if(is_sidebar){
			document.body.classList.add('toggle-sidebar')			
			SetSidebar(false);			
		}
		else{
			document.body.classList.remove('toggle-sidebar')			
			SetSidebar(true);			  
		}	
	}

	useEffect(()=> {
		document.body.classList.remove('toggle-sidebar')		   
		fetchData();				
	},[]) 
	
	const fetchData = async () => {		
		let headers = new Headers();
		headers.append('Content-Type', 'application/json');	
		headers.append('x-access-token', localStorage.getItem('adminToken'));
		const response = await fetch(API.GET_ME,{		
		method: 'POST',  
		headers: headers,		
		});
		const result = await response.json();  		    
		if(result.status===true){ 
			SetAdmin(result.data)			
		} 	
	}

	return (
		<>	
		<header id="header" className="header fixed-top d-flex align-items-center">
		<div className="d-flex align-items-center justify-content-between">
		<Link to="/" className="logo d-flex align-items-center">
		<img src="/assets/img/logo.png" alt="" />
		<span className="d-none d-lg-block">NiceAdmin</span>
		</Link>
		<i className="bi bi-list toggle-sidebar-btn" onClick={()=>toggleSidebar(is_sidebar)}></i>
		</div>
        
		
		{/*search-bar
		<SearchBar />
		*/}		

		<nav className="header-nav ms-auto">
		<ul className="d-flex align-items-center">

		{/*
		<li className="nav-item d-block d-lg-none">
		<Link className="nav-link nav-icon search-bar-toggle" to="#">
		<i className="bi bi-search"></i>
		</Link>
		</li>	
		*/}	
        
		{/*notifications
		<Notifications />
        */}
		
        {/*messages
		<Messages />
		*/}	

		<li className="nav-item dropdown pe-3">
		<Link className="nav-link nav-profile d-flex align-items-center pe-0" to="#" data-bs-toggle="dropdown">		
		{admin.profile_image ? ( 
			<img src={API.FILE_UPLOAD_URL+'admin-profile/thumb-0/'+admin.profile_image} className="rounded-circle profile-img" alt="" />
		) : (
			<img src="/assets/img/profile-img.png" className="img-circle profile-img" alt="" />
		)}
		<span className="d-none d-md-block dropdown-toggle ps-2 profile-name">{admin.name}</span>
		</Link>

		<ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">
		<li className="dropdown-header">
		<h6 className="profile-name">{admin.name}</h6>
		<span>{admin.user_type}</span>
		<div className="p-0">
		<small> 
		Member since {all_function.getFormattedDate(admin.create_date)}
		</small>
		</div>  
		</li>
		<li>
		<hr className="dropdown-divider" />
		</li>

		<li>
		<Link className="dropdown-item d-flex align-items-center" to="/my-profile">
		<i className="bi bi-person"></i>
		<span>My Profile</span>
		</Link>
		</li>
		<li>
		<hr className="dropdown-divider" />
		</li>		
		<li>
		<hr className="dropdown-divider" />
		</li>	

		<li>
		<Link className="dropdown-item d-flex align-items-center" to="/logout">
		<i className="bi bi-box-arrow-right"></i>
		<span>Sign Out</span>
		</Link>
		</li>

		</ul>
		</li>

		</ul>
		</nav>
		</header>
		</>     
    );
}
export default Header;