import React, { useRef, useState, useEffect } from 'react';
import {Helmet} from "react-helmet";
import { Redirect,Link } from 'react-router-dom';
import Swal from 'sweetalert2'

import Meta from '../../common/Meta';
import API from '../../../config/Api';
import all_function from '../../../config/All_function';

let Imagesize_form = (props)=> { 
	
	let imageID = ''
	if(props.match.params.imageID){
		imageID = props.match.params.imageID
	}		

	let page_meta_title     = 'Add Image Size' 	
	if(imageID){		
		page_meta_title     = 'Edit Image Size' 
	}	
	let metaData = {
		meta_title			: page_meta_title,
		meta_description	: '',
		meta_keywords		: '',
	}	
	const [title,set_title]       	    = useState('')	
	const [width,set_width] 			= useState('') 
	const [height,set_height]       	= useState('') 		
	const [new_record,set_new_record]	= useState(false) 

	let [error,set_error] = useState({
		width:"", 
		height:""	
	}) 
	
	const fetchData = async (imageID) => {		
		let headers = new Headers();
		headers.append('Content-Type', 'application/json');	
		headers.append('x-access-token', localStorage.getItem('adminToken'));		
		const response = await fetch(API.IMAGESIZE+'/'+imageID,{			
			method: 'GET',  
			headers: headers
		});
		const result = await response.json();  	
		console.log(result)	    
		if(result.status===true){ 
			set_title(result.data.title)	
			set_width(result.data.width)	
			set_height(result.data.height)			
		} 	
	}
	
	const validateForm = ()=>{
		let error           = {};  
		let isValid         = true;   		
		
		if(!width) {
			isValid 		 = false;
			error.width      = 'Width is required';
		}
		if(!height) {
			isValid 		 = false;
			error.height     = 'Height is required';
		}		
		set_error(error);		
		if(!isValid){			
			Swal.fire({
				icon: 'warning',
				title: 'Opps!',
				text: 'These is some problem with input.',
			}) 		
		}	
		return isValid;		 
	}
	const handleSubmit = async (e) => {	
		e.preventDefault();	
		if(validateForm()){			
            
			if(imageID){
				let headers = new Headers();			
				headers.append('x-access-token', localStorage.getItem('adminToken'));

				let formData = new FormData(e.target);						
				await fetch(API.IMAGESIZE+'/'+imageID,{		
					method: 'PUT',  // *GET, POST, PUT, DELETE, etc.
					headers: headers,
					body: formData 			
				});	
				
				Swal.fire({
					icon: 'success',
					title: 'Congrats!',
					text: 'The information was updated successfully.',
				})         
			}
			else{
				let headers = new Headers();			
				headers.append('x-access-token', localStorage.getItem('adminToken'));

				let formData = new FormData(e.target);							
				await fetch(API.ADD_PAGE,{		
					method: 'POST',  // *GET, POST, PUT, DELETE, etc.
					headers: headers,
					body: formData 			
				});	
				
				Swal.fire({
					icon: 'success',
					title: 'Congrats!',
					text: 'The information was added successfully.',
				})         
			}			
			set_new_record(true)			
		}			
	}
    useEffect( ()=>{	
		if(imageID){	
			fetchData(imageID);
		}
	},[imageID])	

	if(new_record){			
		return <Redirect  to='/imagesize' />			
	}	

  return (
	<>	
		<Meta metaData={metaData} />
		<Helmet defer={false}>
		</Helmet>	    
				
		<div className="pagetitle">
		<h1>{metaData.meta_title} : {title}</h1> 
		<nav>
		<ol className="breadcrumb">
		<li className="breadcrumb-item"><Link to="/">Home</Link></li>
		<li className="breadcrumb-item"><Link to="/imagesize">Image Size</Link></li>
		<li className="breadcrumb-item active">{metaData.meta_title}</li>
		</ol>
		</nav>
		</div>	

		<section className="section">
			<div className="row"> 
			<div className="col-lg-8">
				<div className="card">
				<div className="card-body">	
					<form className="row g-3" name="pageForm" id="pageForm" method="post" encType="multipart/form-data" onSubmit={handleSubmit}>
					<div className="col-12 pt-3">
						<label htmlFor="width" className="form-label">Width</label>
						<input type="text" className="form-control" id="width" name="width" value={width} 
						onChange={ (e) => { 
							set_width(e.target.value)							
						}} 
						/>
						{error.width && 
							<div className="error-msg">{error.width}</div>    
						}   
					</div>
					<div className="col-12">
						<label htmlFor="height" className="form-label">Height</label>
						<input type="text" className="form-control" id="height" name="height" value={height} onChange={(e)=>set_height(e.target.value)} />
						{error.height && 
							<div className="error-msg">{error.height}</div>    
						}   
					</div>				

					<div className="col-12">
						<label htmlFor="inputAddress" className="form-label"></label>
						<button type="submit" className="btn btn-primary">Submit</button>
					</div>					
					</form>
				</div>
				</div>
			</div>
			</div>
		</section>	

	</>
    );  
}

export default Imagesize_form;