// https://redux.js.org/tutorials/fundamentals/part-6-async-logic 

import React, { Component } from 'react';
import {Helmet} from "react-helmet";
import { Link } from "react-router-dom";

import Swal from 'sweetalert2'
import Meta from '../../common/Meta';
import API from '../../../config/Api';

import validation from '../../../config/Validation';
import SingleImageUpload from '../../common/single_image_upload/SingleImageUpload';

import ProfileCard from './child/ProfileCard';
import ProfileList from './child/ProfileList';
import ChangePassword from './child/ChangePassword';

class My_profile extends Component{
    constructor(props){
		super(props)
		
		let social_media = [
			{name:'twitter',title:'Twitter Profile',value:'',error:''},
			{name:'facebook',title:'Facebook Profile',value:'',error:''},
			{name:'instagram',title:'Instagram Profile',value:'',error:''},
			{name:'linkedin',title:'Linkedin Profile',value:'',error:''},           
		]

		this.state = {
			metaData:{
				meta_title			: 'My Profile',
				meta_description	: '',
				meta_keywords		: '',
			},		
			loader:true, 
			admin: '',     
			profile_image:"", 
			name:"",
			about_me:"",
			address:"",   
			phone_number:"",	
			email:"", 
			social_media:social_media,           	
			errors:{
				profile_image:"", 
				name:"",
				about_me:"",
				address:"",   
				phone_number:"",	
				email:""
			},
			common_error: ''		
		}		 
		this.handleChange       = this.handleChange.bind(this);	
		this.handleSubmit       = this.handleSubmit.bind(this);	
    }   
  	async componentDidMount(){		
	
		let headers = new Headers();
		headers.append('Content-Type', 'application/json');	
		headers.append('x-access-token', localStorage.getItem('adminToken'));

		const response = await fetch(API.GET_ME,{		
		method: 'POST',  
		headers: headers,
		body: ''
		});
		const result = await response.json();  		    
		if(result.status===true){ 
			this.setData(result)	
		} 	
	} 
	setData(result){
		let social_media = this.state.social_media;
		for(let i=0; i<social_media.length; i++){  
			let SS = result.data.social_media; 
			social_media[i]['value'] = SS[social_media[i]['name']] ? SS[social_media[i]['name']] : ''
		}
		this.setState({	
			admin: result.data,           
			profile_image: result.data.profile_image,
			name: result.data.name,
			about_me: result.data.about_me,
			address: result.data.address,           
			phone_number: result.data.phone_number,	
			email: result.data.email,
			social_media:social_media,				
			loader: false		
		});	
		this.edit_header_profile()   
	}
	edit_header_profile(){

		let all_pp_img = document.querySelectorAll('.profile-img');
		all_pp_img.forEach((item) => {
			if(this.state.profile_image){
				setTimeout(() => {
					item.src = API.FILE_UPLOAD_URL+'admin-profile/thumb-0/'+this.state.profile_image;               
				}, 500);			
			}
			else{
				item.src = "/assets/img/profile-img.png";
			}			
		})		
		
        let all_pp_name = document.querySelectorAll('.profile-name');
		all_pp_name.forEach((item) => {
			item.innerHTML = this.state.name;			
		})		

		
	}
	handleChange(e){		
		e.preventDefault();
		const { name, value } = e.target;
		let errors = this.state.errors;	    
		
		switch(name){
			
			case 'profile_image': 
				if(!value){ 
					errors.profile_image = 'Profile image is required';
				}
				else{
					errors.profile_image = '';
				}
				break;			
			case 'name': 
				if(!value){ 
					errors.name = 'Name is required';
				}
				else{
					errors.name = '';
				}
				break;
			case 'email': 
				if(!value){ 
					errors.email = 'Email is required';
				}
				else if(!validation.validateEmail(value)){ 
					errors.email = 'Email is not valid!';
				}
				else{
					errors.email = '';
				}
				break;
			case 'phone_number': 
				if(!value){ 
					errors.phone_number = 'Phone Number is required';
				}
				else{
					errors.phone_number = '';
				}
				break;          
			default:
				break; 
		}		
		this.setState({
			errors, [name]: value,
			common_error  : ''	
		});
	}
	handleChange_social_media(i,e){        
		let social_media = this.state.social_media;
		social_media[i]['value'] = e.target.value    
		
		if( social_media[i]['value'] && !validation.validateUrl(social_media[i]['value']) ){ 
			social_media[i]['error'] = 'Please enter valid url';
		}
		else{
			social_media[i]['error'] = '';
		}        
		this.setState({social_media:social_media});
	}

	validateForm(){
		const {		
			name, 
			phone_number,       
			email
		} = this.state; 

		let errors                = this.state.errors;  
		let isValid               = true;   
		
		if(!document.getElementById("profile_image").value) { 
			isValid 		       = false;
			errors.profile_image   = 'Profile image is required';
		}	
		if(!name) {
			isValid 		       = false;
			errors.name            = 'Name is required';
		}
		if(!email) {
			isValid 		       = false;
			errors.email           = 'Email is required';
		}	
		else if(!validation.validateEmail(email)) {
			isValid 		       = false;
			errors.email           = 'Email is not valid!';
		}
		if(!phone_number) {
			isValid 		       = false;
			errors.phone_number    = 'Phone Number is required';
		}	
		
		let social_media = this.state.social_media;
		for(let i=0; i<social_media.length; i++){  
			if( social_media[i]['value'] && !validation.validateUrl(social_media[i]['value']) ){ 
				isValid 		         = false;
				social_media[i]['error'] = 'Please enter valid url';
			} 
		}

		this.setState({
			errors : errors,
			social_media:social_media		
		});	
		return isValid;		 
	}
	async handleSubmit(e){
		e.preventDefault();
		
		if(this.validateForm()){
			let headers = new Headers();			
			headers.append('x-access-token', localStorage.getItem('adminToken'));
			 
			let formData = new FormData(e.target);	
			formData.append('adminID',this.state.admin.adminID); 			

			const response = await fetch(API.EDIT_ME,{		
				method: 'POST',  // *GET, POST, PUT, DELETE, etc.
				headers: headers,
				body: formData 			
			});	

			const result = await response.json();
			this.setData(result)
			
			Swal.fire({
				icon: 'success',
				title: 'Congrats!',
				text: 'The information was updated successfully.',
			})           
			
		}			
	}
    render(){	
		const {errors} 	   = this.state; 
		const social_media = this.state.social_media; 
		
		var socialInputsArray = [];
		for(let i=0; i<social_media.length; i++){       
			socialInputsArray[i] = (             
				<div className="row mb-3" key={i}>
					<label htmlFor={social_media[i].name} className="col-md-4 col-lg-3 col-form-label">{social_media[i].title}</label>
					<div className="col-md-8 col-lg-9">
					<input 
					key={i}
					type="text"
					className={`form-control ${social_media[i].error ? 'error': ''} `}
					id={social_media[i].name}
					//name={social_media[i].name}
					name={`social_media[${social_media[i].name}]`}
					value={social_media[i].value}				
					onChange={(e)=>{this.handleChange_social_media(i,e)}}                
					/>
					{social_media[i].error && 
					<div className="error-msg">{social_media[i].error}</div>    
					}  
					</div>
				</div>
				)
		}       
		return(
		<>	
			<Meta metaData={this.state.metaData} />		
			<Helmet defer={false}>	
			</Helmet>
			
			<div className="pagetitle">
				<h1>Profile</h1>
				<nav>
				<ol className="breadcrumb">
					<li className="breadcrumb-item"><Link to="/">Home</Link></li>				
					<li className="breadcrumb-item active">Profile</li>
				</ol>
				</nav>
			</div>

			<section className="section profile">
				<div className="row">
				<div className="col-xl-4">
				<ProfileCard admin={this.state.admin} profile_image={this.state.profile_image} />
				</div>

				<div className="col-xl-8">

					<div className="card">
					<div className="card-body pt-3">
					
						<ul className="nav nav-tabs nav-tabs-bordered">
							<li className="nav-item">
							<button className="nav-link active" data-bs-toggle="tab" data-bs-target="#profile-overview">Overview</button>
							</li>
							<li className="nav-item">
							<button className="nav-link" data-bs-toggle="tab" data-bs-target="#profile-edit">Edit Profile</button>
							</li>	
							<li className="nav-item">
							<button className="nav-link" data-bs-toggle="tab" data-bs-target="#profile-change-password">Change Password</button>
							</li>
						</ul>

						<div className="tab-content pt-2">

						<div className="tab-pane fade show active profile-overview" id="profile-overview">
						<ProfileList admin={this.state.admin}  />
						</div>

						<div className="tab-pane fade profile-edit pt-3" id="profile-edit">					
						<form name="userProfileForm" id="userProfileForm" method="post" encType="multipart/form-data" onSubmit={this.handleSubmit}>
						<div className="row mb-3">
						    <label htmlFor="fullName" className="col-md-4 col-lg-3 col-form-label">Profile Image</label>
							<div className="col-md-8 col-lg-9">
							<SingleImageUpload  
							data={{								
								'input_file_name'	:'profile_image',	
								'width'				:500,		
								'height'			:500,									
								'upload_folder_name':'admin-profile',
								'allowedExtensions' :'jpg, jpeg, png, gif', // 'doc, docx, xls, xlsx'
								'maxFileSize'		:6, //1MB														
								'table'		        :'admin',
								'table_id'		    :'adminID',
								'table_id_value'	:this.state.admin.adminID,
								'error'	            :errors.profile_image,
								'uploadedFile'	    :this.state.profile_image
							}}
							onChange={ (value)=>{ 
								this.setState({
								'profile_image':value								
								}) 
								this.edit_header_profile()
							}}												             
							/>							
							</div>
						</div>

						<div className="row mb-3">
							<label htmlFor="fullName" className="col-md-4 col-lg-3 col-form-label">Full Name</label>
							<div className="col-md-8 col-lg-9">
							<input type="text" className={`form-control ${errors.name ? 'error': ''} `} id="fullName" name="name" 
							value={this.state.name}
							onChange={this.handleChange} />
							{errors.name && 
								<div className="error-msg">{errors.name}</div>    
							}   
							</div>
						</div>

						<div className="row mb-3">
							<label htmlFor="about_me" className="col-md-4 col-lg-3 col-form-label">About</label>
							<div className="col-md-8 col-lg-9">
							<textarea className={`form-control ${errors.about_me ? 'error': ''} `} id="about_me" name="about_me" style={{height:'100px'}} 
							value={this.state.about_me}
							onChange={this.handleChange} />
							{errors.about_me && 
								<div className="error-msg">{errors.about_me}</div>    
							}   
							</div>
						</div>

						<div className="row mb-3">
							<label htmlFor="address" className="col-md-4 col-lg-3 col-form-label">Address</label>
							<div className="col-md-8 col-lg-9">
							<input type="text" className={`form-control ${errors.address ? 'error': ''} `} id="address" name="address"
							value={this.state.address}
							onChange={this.handleChange} />
							{errors.address && 
								<div className="error-msg">{errors.address}</div>    
							}   
							</div>
						</div>

						<div className="row mb-3">
							<label htmlFor="phone_number" className="col-md-4 col-lg-3 col-form-label">Phone</label>
							<div className="col-md-8 col-lg-9">
							<input type="text" className={`form-control ${errors.phone_number ? 'error': ''} `} id="phone_number" name="phone_number" 
							value={this.state.phone_number}
							onChange={this.handleChange} />
							{errors.phone_number && 
								<div className="error-msg">{errors.phone_number}</div>    
							}   
							</div>
						</div>

						<div className="row mb-3">
							<label htmlFor="email" className="col-md-4 col-lg-3 col-form-label">Email</label>
							<div className="col-md-8 col-lg-9">
							<input type="text" className={`form-control ${errors.email ? 'error': ''} `} id="email" name="email" 
							value={this.state.email}
							onChange={this.handleChange} />
							{errors.email && 
								<div className="error-msg">{errors.email}</div>    
							}   
							</div>
						</div>  
						
						{socialInputsArray}

						<div className="row mb-3">
							<label htmlFor="Linkedin" className="col-md-4 col-lg-3 col-form-label"></label>
							<div className="col-md-8 col-lg-9">
							<button type="submit" className="btn btn-primary">Save Changes</button>
							</div>
						</div>						
						</form>
						</div>					

						<div className="tab-pane fade pt-3" id="profile-change-password">
						<ChangePassword  />
						</div>

						</div>

					</div>
					</div>

				</div>
				</div>
			</section>
		</>
		);
    }
}
export default My_profile;