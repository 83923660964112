
//Routing Hint: https://reactrouter.com/web/guides/quick-start
//Not : create and use 'folder' name must be in small letter

import Dashboard from '../components/pages/dashboard/Dashboard';
import Login from '../components/pages/login/Login';
import Logout from '../components/pages/logout/Logout';
import Forgot_password from '../components/pages/forgot_password/Forgot_password';
import My_profile from '../components/pages/my_profile/My_profile';
import general_settings from '../components/pages/general_settings/General_settings';

import Page from '../components/pages/page/Page';
import Page_form from '../components/pages/page/Page_form';

import Block from '../components/pages/block/Block';
import Block_form from '../components/pages/block/Block_form';

import Imagesize from '../components/pages/imagesize/Imagesize';
import Imagesize_form from '../components/pages/imagesize/Imagesize_form';

const routes = [
  {path: '/', exact: true, component: Dashboard },
  {path: '/login', exact: true, component: Login },
  {path: '/logout', exact: true, component: Logout },
  {path: '/forgot-password', exact: true, component: Forgot_password },
  //{path: '/my-profile/:admin_id', exact: true, component: My_profile },
  {path: '/my-profile', exact: true, component: My_profile },
  {path: '/settings', exact: true, component: general_settings },

  {path: '/page', exact: true, component: Page },
  {path: '/add-page', exact: true, component: Page_form },
  {path: '/edit-page/:pageID', exact: true, component: Page_form },

  {path: '/block', exact: true, component: Block },
  {path: '/add-block', exact: true, component: Block_form },
  {path: '/edit-block/:blockID', exact: true, component: Block_form },

  {path: '/imagesize', exact: true, component: Imagesize },
  {path: '/edit-imagesize/:imageID', exact: true, component: Imagesize_form },  
  
  /*
  /*
  {path: '/tacos', component: Dashboard, routes: [
      { path: '/tacos/bus', component: Dashboard},
      { path: '/tacos/cart', component: Dashboard}
    ]
  }
  */
];

export default routes;