import React, { Component } from 'react';
import {Helmet} from "react-helmet";
import { Redirect,Link } from 'react-router-dom';
import Meta from '../../common/Meta';
import API from '../../../config/Api';
import validation from '../../../config/Validation';
import './Login.css';

class Login extends Component {  

	constructor(props) {
		super(props);	

		let email     = '';
		let password  = '';
		let checked   = false;
			
		if(localStorage.getItem('checkbox')){			
			checked  = true	
			email    = localStorage.getItem('email')	
			password = localStorage.getItem('password')				
		} 
		
		this.state = {
			metaData:{
				meta_title			: 'Login',
				meta_description	: '',
				meta_keywords		: '',
			},		
			loggedIn    : false,
			email		: email,
			password	: password,	
			checked     : checked,	 
			errors: {
			  email	: '',
			  password: '',
			},
			common_error: ''
		};
		
		this.handleChange       = this.handleChange.bind(this);	
		this.handleSubmit       = this.handleSubmit.bind(this);	
		this.handleRememberMe   = this.handleRememberMe.bind(this);
	}  	
	async componentDidMount() {				
		let headers = new Headers();
		headers.append('Content-Type', 'application/json');	
		headers.append('x-access-token', localStorage.getItem('adminToken'));	
		const response = await fetch(API.GET_ME,{		
		  method: 'POST',  
		  headers: headers,
		  body: ''
		});
		const result = await response.json(); 		
		if(result.status===true){
			this.setState({			
			  loggedIn: true
			});
		} 		
	} 
	handleChange(e){		
		e.preventDefault();
		const { name, value } = e.target;
		let errors = this.state.errors;	    
		
		switch (name) {
		  case 'email': 
			if(!validation.validateEmail(value)){ 
				errors.email = 'Email is not valid!';
			}
			else{
				errors.email = '';
			}
			break;
		  case 'password': 
			if(!value){
				errors.password = 'Password is required';
			}
			else{
				errors.password = '';
			}	
			break;
		  default:
			break; 
		}		
		this.setState({
			errors, [name]: value,
			common_error  : ''	
		});
	}
	handleRememberMe(e){		
		this.setState({ 			
			checked: !this.state.checked 
		});
	}
    validateForm(){
		const {email,password}    = this.state; 
		let errors                = this.state.errors;  
		let isValid               = true;   
		
		if(!validation.validateEmail(email)) {
			isValid 		       = false;
			errors.email           = 'Email is not valid!';
		}			
		if(!password){
			isValid 			   = false;
			errors.password        = 'Password is required';
		}	
		this.setState({
			errors : errors			
		});	
		return isValid;		 
	}
    async handleSubmit(e){
		e.preventDefault();        
		if(this.validateForm()){		
			
			let headers = new Headers();
			headers.append('Content-Type', 'application/json');	
			
			const response = await fetch(API.LOGIN,{		
				method: 'POST',  // *GET, POST, PUT, DELETE, etc.
				headers: headers,
				body: JSON.stringify(this.state) 
			});
			const result = await response.json();			
			if(result.status){		
						
				localStorage.setItem('adminToken',result.token);	
				localStorage.setItem('admin',JSON.stringify(result.data));				
				localStorage.setItem('admin_id',result.data.adminID);				
				this.setState({
					loggedIn     : true,
					common_error : ''
				})
				
				if(this.state.checked){
					localStorage.setItem('checkbox','1');
					localStorage.setItem('email',this.state.email);
					localStorage.setItem('password',this.state.password);
				}
				else{
					localStorage.removeItem('checkbox');
					localStorage.removeItem('email');
					localStorage.removeItem('password');		
				}				
			}
			else{
				this.setState({
					common_error : result.message
				})	
			}			
		}			
	}
	
    render() {	
	if(this.state.loggedIn===true){			
		return <Redirect  to='/' />			
	}	
	const {errors} = this.state;	
		
    return ( 
	<>
		<Meta metaData={this.state.metaData} />
		<Helmet defer={false}>
		</Helmet>
		
		<main>
		<div className="container">
		<section className="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
		<div className="container">
		<div className="row justify-content-center">
		<div className="col-lg-4 col-md-6 d-flex flex-column align-items-center justify-content-center">
		<div className="d-flex justify-content-center py-4">
		<Link to="/login" className="logo d-flex align-items-center w-auto">
			<img src="/assets/img/logo.png" alt="" />			
		</Link>
		</div>
		<div className="card mb-3">
		<div className="card-body">
			<div className="pt-4 pb-2">
			<h5 className="card-title text-center pb-0 fs-4">Admin Login</h5>
			<p className="text-center small">Sign in to start your session</p>
			</div>
			{this.state.common_error &&
                <div className="p-2 pl-3 mb-3 bg-danger text-white">{this.state.common_error}</div>   
            }  
			<form className="row g-3 needs-validation" method="post" onSubmit={this.handleSubmit}>
			
			<div className="col-12">
			<div className={`input-group ${errors.email ? 'error' : ''}`}>
			<input 
			type="text" 
			className="form-control" 
			placeholder="Email" 
			id="email"
			name="email" 
			value={this.state.email} 
			onChange={this.handleChange}
			autoComplete="off"/>				  
			</div>
			{errors.email && 
			<div className="error-msg">{errors.email}</div>    
			}  				
			</div>

			<div className="col-12">				
			<div className={`input-group mt-3  ${errors.password ? 'error' : ''}`}> 
			<input 
			type="password" 
			className="form-control" 
			placeholder="Password" 
			id="password"
			name="password" 
			value={this.state.password} 
			onChange={this.handleChange}
			autoComplete="off"/>				  
			</div>
			{errors.password &&
			<div className="error-msg">{errors.password}</div>    
			}  
			</div>

			<div className="col-12">
			<div className="form-check">				
			<input 
			className="form-check-input"
			type="checkbox" 
			name="rememberMe" 
			id="rememberMe"
			value="1" 
			checked={this.state.checked} 
			onChange={this.handleRememberMe} />
			<label className="form-check-label" htmlFor="rememberMe">Remember me</label>
			</div>
			</div>
			<div className="col-12">
			<button className="btn btn-primary w-100" type="submit">Login</button>
			</div>
			<div className="col-12">
			<p className="small mb-0"> <Link to="/forgot-password">I forgot my password</Link> </p>			
			</div>
			</form>

		</div>
		</div>
		<div className="credits">
		Designed by <a href="https://bootstrapmade.com/">BootstrapMade</a>
		</div>
		</div>
		</div>
		</div>
		</section>
		</div>
		</main>
	</>
    );
  }
}
export default Login;

