import React, { useState, useEffect } from 'react';
import {Helmet} from "react-helmet";
import { Link } from 'react-router-dom';
import Parser from 'html-react-parser';
import Pagination from "../../common/pagination/Pagination";
import Meta from '../../common/Meta';
import API from '../../../config/Api';
import all_function from '../../../config/All_function';
import './Imagesize.css';

let  Imagesize = (props)=> {    
    
	const queryParams  = new URLSearchParams(window.location.search) 

    let __page_number = 1;
	if(queryParams.get('page_number')){		
		__page_number = parseInt(queryParams.get('page_number')) 
	}
	let __title = "";
	if(queryParams.get('title')){		
		__title = queryParams.get('title') 
	}	

	const item_per_page = 25	
	const metaData = {
		meta_title			: 'Image Size',
		meta_description	: '',
		meta_keywords		: '',
	} 		
	
	const [page_number,set_page_number]   	= useState(__page_number)
	const [title,set_title] 		        = useState(__title)	
	const [total,set_total] 				= useState(0)
	const [records,set_records] 			= useState(null)	
	let   [sl_no,set_sl_no] 			    = useState(0)	
     
	const fetchData = async (page_number)=> {	
		let headers = new Headers();
		headers.append('Content-Type', 'application/json');	
		headers.append('x-access-token', localStorage.getItem('adminToken'));	
		
		let params = {
			'title':title,				
			'item_per_page':item_per_page,
			'page_number':page_number,
		}
		let querystring = all_function.objToQuerystring(params)
		const response = await fetch(API.IMAGESIZE+'?'+querystring,{		
			method: 'GET',  
			headers: headers
		})
		const result = await response.json();	
		if(result){				
			set_total(result.option.total)
			set_records(result.data)
			set_sl_no(result.option.sl_no)
			set_page_number(page_number)		
		}					   
	} 
	const setBrowserUrl = (pgNo)=>{	
		const location = new URL(window.location);
		location.search="";
		if(title){
			location.searchParams.set('title', title);
		}		
		if(pgNo){
			location.searchParams.set('page_number', pgNo);
		}
		window.history.pushState({},'', location);
	}
	const handleSubmit = (e)=>{
		e.preventDefault();	
		setBrowserUrl(0)	
		fetchData(1)	
	}
	
	const handlePaginate = (pgNo)=>{
		setBrowserUrl(pgNo)		
		fetchData(pgNo); 		
	}	
	    
	useEffect( ()=>{			
		if(page_number){	
			fetchData(page_number);
		}
	},[page_number])	

	let pageCount   = Math.ceil(total/item_per_page);  

    return (
	<>	
		<Meta metaData={metaData} />		
		<Helmet defer={false}>	
		<script>
		
		</script>
		</Helmet>	    
				
		<div className="pagetitle">
			<h1>Image Size</h1>
			<nav>
			<ol className="breadcrumb">
				<li className="breadcrumb-item"><Link to="/">Home</Link></li>				
				<li className="breadcrumb-item active">Image Size</li>
			</ol>
			</nav>
		</div>

		
		<section className="section">
		<div className="row">
			<div className="col-lg-12">
				<div className="card">				
				
				<form id="frmFilter" name="frmFilter" method="post" encType="multipart/form-data" onSubmit={handleSubmit}>				
				<div className="card-body table-responsive">					         
				<table className="table table-hover">
					<thead>
					<tr>
						<th scope="col" width="5%">SL</th>
						<th scope="col">Title</th>
						<th scope="col">Width</th>
						<th scope="col">Height</th>
						<th scope="col" width="10%" className="text-center">Action</th>						
					</tr>
					<tr>
						<td>&nbsp;</td>						
						<td>
						<input className="form-control" type="text" 
						name="title" 
						value={title}  
						onChange={ (e)=> set_title(e.target.value) } />	
						</td>
						<td>&nbsp;</td>	
						<td>&nbsp;</td>	

						<td className="text-center">
						<button type="submit" name="OkFilter" id="OkFilter" className="btn  btn-primary">Filter</button>
						</td>
					</tr>
					</thead>
					<tbody>
					{records &&
						records.map((doc,index) => ( 
						<tr key={index+1}>							
							<td>{++sl_no}</td>
							<td>{Parser(doc.title)}</td>
							<td>{doc.width}</td>
							<td>{doc.height}</td>							
							<td className="text-center"> 
							    
								<Link to={`edit-imagesize/${doc.imageID}`}>
								<button type="button" className="btn btn-info btn-sm" title='Edit'>
								<i className="bi bi-pencil-square"></i>
								</button>
								</Link>
								
							</td>
						</tr> 						
                       ))}  									           
					</tbody>
				</table>				
				{records && records.length===0 &&						
					<div className='text-center'>No record Found!</div>						
  				} 			
				</div>
				</form>
				
				{pageCount>1 && 
				    <div className="card-body table-responsive">
					<Pagination data={{
					'total'			:total,
					'item_per_page'	:item_per_page,
					'page_number'	:page_number,
					'handlePaginate':handlePaginate						
					}}/>
					</div>
				}				
                
			</div>    
			</div>
		</div>		
		</section>
		
	</>
    );  
}

export default Imagesize;