
import React, { Component } from "react";
import Parser from 'html-react-parser';
import { Link } from 'react-router-dom';
import API from '../../../config/Api';

class Footer extends Component {
	constructor(props) {
		super(props);		
		this.state = {
			copyright_text: '',	
		};		
	} 
	async componentDidMount() {	
		let headers = new Headers();
		headers.append('Content-Type', 'application/json');	
		headers.append('x-access-token', localStorage.getItem('adminToken'));		
		const response = await fetch(API.SITE_OPTION,{		
			method: 'POST',  
			headers: headers,
			body: JSON.stringify({
			'option_name':'copyright_text'
		}) 
		});
		const result = await response.json();				
		this.setState({
			copyright_text: result.Html,
		});	   
	}
    componentWillUnmount() {
		// fix Warning: Can't perform a React state update on an unmounted component
		this.setState = (state,callback)=>{
			return;
		};
	}
	render(){
		return (
			<>
			<footer id="footer" className="footer">
				<div className="copyright">
				{this.state.copyright_text ? Parser(this.state.copyright_text) : '' }
				</div>
				<div className="credits">     
				Designed by <a href="https://bootstrapmade.com/">BootstrapMade</a>
				</div>
			</footer>
			<Link to="#" className="back-to-top d-flex align-items-center justify-content-center"><i className="bi bi-arrow-up-short"></i></Link>
			</>
		);
	}}

export default Footer;