

//import React, { Component } from "react";
//import { Link } from 'react-router-dom';
import API from '../config/Api';
//const ADMIN_PATH = API.ADMIN_PATH;

const All_function = { 
  localStorage_setWithExpiry : (key, value, ttl)=> {
    const now = new Date()
    // `item` is an object which contains the original value
    // as well as the time when it's supposed to expire
    const item = {
      value: value,
      expiry: now.getTime() + ttl,
    }
    localStorage.setItem(key, JSON.stringify(item))
  },  
  localStorage_getWithExpiry : (key)=> {
      const itemStr = localStorage.getItem(key)
      // if the item doesn't exist, return null
      if(!itemStr){
        return null
      }
      const item = JSON.parse(itemStr)
      const now  = new Date()
      // compare the expiry time of the item with the current time
      if(now.getTime() > item.expiry){
        // If the item is expired, delete the item from storage
        // and return null
        localStorage.removeItem(key)
        return null
      }
      return item.value
  },
  generateRandomNumber : (length)=>{
    var randomValues = '';
    var stringValues = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';  
    var sizeOfCharacter = stringValues.length;  
    for (var i = 0; i < length; i++) {
      randomValues = randomValues+stringValues.charAt(Math.floor(Math.random() * sizeOfCharacter));
    }
    return randomValues;
  },
  getFormattedDate: (myDateTime)=> {
    var dateTime  = new Date(Date.parse(myDateTime));
    var date      = dateTime.getDate().toString().length > 1 ? dateTime.getDate() : '0' + dateTime.getDate();
    var month     = dateTime.getMonth().toString().length > 1 ? dateTime.getMonth() + 1 : '0' + (dateTime.getMonth() + 1);
    var hours     = dateTime.getHours().toString().length > 1 ? dateTime.getHours() : '0' + dateTime.getHours();
    var minutes   = dateTime.getMinutes().toString().length > 1 ? dateTime.getMinutes() : '0' + dateTime.getMinutes();

    var formattedDate = date + '/' + month + '/' + dateTime.getFullYear() + ' ' + hours + ':' + minutes;
    return formattedDate;
  },
  get_image_size: async (imageID)=> {
    let headers = new Headers();
		headers.append('Content-Type', 'application/json');	
		headers.append('x-access-token', localStorage.getItem('adminToken'));	
		const response = await fetch(API.IMAGESIZE+'/'+imageID,{		
        method: 'GET',  
        headers: headers       
		});
		const result = await response.json();		
    return result;		
  },
  objToQuerystring : (obj)=> {
    const keyValuePairs = [];
    for (let i = 0; i < Object.keys(obj).length; i += 1) {
      keyValuePairs.push(`${encodeURIComponent(Object.keys(obj)[i])}=${encodeURIComponent(Object.values(obj)[i])}`);
    }
    return keyValuePairs.join('&');
  }

}

export default All_function;