import React, { useRef, useState, useEffect } from 'react';
import {Helmet} from "react-helmet";
import { Redirect,Link } from 'react-router-dom';
import Swal from 'sweetalert2'

import { Editor } from '@tinymce/tinymce-react';
import editor_config from '../../../config/tinymce_config';

import Meta from '../../common/Meta';
import API from '../../../config/Api';
import SingleImageUpload from '../../common/single_image_upload/SingleImageUpload';
import all_function from '../../../config/All_function';

let Block_form = (props)=> { 
	
	let blockID = ''
	if(props.match.params.blockID){
		blockID = props.match.params.blockID
	}	
	

	let page_meta_title     = 'Add Block' 	
	if(blockID){		
		page_meta_title     = 'Edit Block' 
	}	
	let metaData = {
		meta_title			: page_meta_title,
		meta_description	: '',
		meta_keywords		: '',
	} 
		
	const editor1_Ref 									= useRef(null); 
	const [new_record,set_new_record]     				= useState(false) 
	const [title,set_title] 					        = useState('') 
	const [identity,set_identity]                   	= useState('') 	
	const [description,set_description]   			    = useState('') 
	const [status,set_status]   			            = useState('') 			
		
	let [error,set_error] = useState({
		title:"", 
		identity:""		
	}) 	
	
	const fetchData = async (blockID) => {		
		let headers = new Headers();
		headers.append('Content-Type', 'application/json');	
		headers.append('x-access-token', localStorage.getItem('adminToken'));
		const response = await fetch(API.BLOCK+'/'+blockID,{		
			method: 'GET',  
			headers: headers
		});
		const result = await response.json(); 
		console.log(result.data) 		    
		if(result.status===true){ 	
			set_title(result.data.title)	
			set_identity(result.data.identity)
			set_description(result.data.description)	
			set_status(result.data.status)				
		} 	
	}
	
	const setIdentityTxt = (e) => {		  
		let value   = e.target.value;	
		let text    = value.toLowerCase().replace(/[^a-z0-9\s]/gi, '').replace(/[_\s]/g, '-');	
		set_identity(text)		
	}
	
	const validateForm = ()=>{
		let error           = {};  
		let isValid         = true;   		
		
		if(!title) {
			isValid 		 = false;
			error.title      = 'Title is required';
		}
		if(!blockID && !identity) {
			isValid 		 = false;
			error.identity   = 'Identity is required';
		}		
		set_error(error);		
		if(!isValid){			
			Swal.fire({
				icon: 'warning',
				title: 'Opps!',
				text: 'These is some problem with input.',
			}) 		
		}	
		return isValid;		 
	}
	const handleSubmit = async (e) => {	
		e.preventDefault();	
		if(validateForm()){			
            
			if(blockID){
				console.log(blockID)
				let headers = new Headers();			
				headers.append('x-access-token', localStorage.getItem('adminToken'));

				let formData = new FormData(e.target);	
				formData.append('blockID',blockID); 
				formData.append('description',description); 
				await fetch(API.BLOCK+'/'+blockID,{		
					method: 'PUT',  // *GET, POST, PUT, DELETE, etc.
					headers: headers,
					body: formData 			
				});	
				
				Swal.fire({
					icon: 'success',
					title: 'Congrats!',
					text: 'The information was updated successfully.',
				})         
			}
			else{
				let headers = new Headers();			
				headers.append('x-access-token', localStorage.getItem('adminToken'));

				let formData = new FormData(e.target);	
				formData.append('description',description);							
				await fetch(API.BLOCK,{		
					method: 'POST',  // *GET, POST, PUT, DELETE, etc.
					headers: headers,
					body: formData 			
				});	
				
				Swal.fire({
					icon: 'success',
					title: 'Congrats!',
					text: 'The information was added successfully.',
				})         
			}			
			set_new_record(true)			
		}			
	}
    useEffect( ()=>{	
		if(blockID){	
			fetchData(blockID);
		}

	},[blockID])	

	if(new_record){			
		return <Redirect  to='/block' />			
	}	

  return (
	<>	
		<Meta metaData={metaData} />
		<Helmet defer={false}>
		</Helmet>	    
				
		<div className="pagetitle">
		<h1>{metaData.meta_title}</h1>
		<nav>
		<ol className="breadcrumb">
		<li className="breadcrumb-item"><Link to="/">Home</Link></li>
		<li className="breadcrumb-item"><Link to="/block">Block</Link></li>
		<li className="breadcrumb-item active">{metaData.meta_title}</li>
		</ol>
		</nav>
		</div>	

		<section className="section">
			<div className="row"> 
			<div className="col-lg-8">
				<div className="card">
				<div className="card-body">	
					<form className="row g-3" name="pageForm" id="pageForm" method="post" encType="multipart/form-data" onSubmit={handleSubmit}>
					<div className="col-12 pt-3">
						<label htmlFor="title" className="form-label">Title</label>
						<input type="text" className="form-control" id="title" name="title" value={title} 
						onChange={ (e) => { 
							set_title(e.target.value)
							setIdentityTxt(e)
						}} 
						/>
						{error.title && 
							<div className="error-msg">{error.title}</div>    
						}   
					</div>

					{!blockID && 
					<div className="col-12">
						<label htmlFor="identity" className="form-label">Identity</label>
						<input type="text" className="form-control" id="identity" name="identity" value={identity} onChange={(e)=>set_identity(e.target.value)} />
						{error.identity && 
							<div className="error-msg">{error.identity}</div>    
						}   
					</div>
					}
					
					<div className="col-12">
					   <label htmlFor="description" className="form-label">Description</label>	                       
					   <Editor	
					        id="editor1"						
							onInit={(evt, editor) => editor1_Ref.current = editor}
							initialValue={description}
							init={editor_config}

							onEditorChange={(e)=>{
								//console.log(editor1_Ref.current.getContent());
								//set_page_content(editor1_Ref.current.getContent())								
							}}	
							
							onSubmit={(e)=>{
								//console.log(editor1_Ref.current.getContent());
								set_description(editor1_Ref.current.getContent())								
							}}

						/>
						{error.description && 
							<div className="error-msg">{error.description}</div>    
						}  						
							
					</div>								

					
					<div className="col-12">
						<label htmlFor="status" className="form-label">Status</label>
						<select className="form-select" name="status" value={status} onChange={ (e)=> set_status(e.target.value) }>							
							<option value="0">Inactive</option>
							<option value="1">Active</option>
						</select>
					</div>

					<div className="col-12">
						<label htmlFor="inputAddress" className="form-label"></label>
						<button type="submit" className="btn btn-primary">Submit</button>
					</div>					
					</form>
				</div>
				</div>
			</div>
			</div>
		</section>	

	</>
    );  
}

export default Block_form;