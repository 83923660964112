
import React from 'react';
import { Link } from 'react-router-dom';

const Sidebar = (props)=>{	
   
	let current_path     		= window.location.pathname;
	const dashboard_menu 		= ['/'];  
	const page_menu       		= ['/page','/block','/banner-category'];  
	const testimonial_menu   	= ['/testimonial'];
	const service_menu    		= ['/service','/service-category'];
	const emailtemplate_menu    = ['/email-templates'];
	const users_menu    		= ['/users'];
	const settings_menu    		= ['/settings'];
	const faq_menu    			= ['/faq','/faq-category'];
	const product_menu    		= ['/product','/product-category'];
	const imagesize_menu    	= ['/imagesize'];
	const contact_menu    		= ['/contact'];	
	
	return (
		<>		    	   
		<aside id="sidebar" className="sidebar">
		<ul className="sidebar-nav" id="sidebar-nav">

			<li className="nav-item">
			<Link to="/" className={`nav-link ${dashboard_menu.includes(current_path) ? '' : 'collapsed'}`} >
			<i className="bi bi-grid"></i>
			<span>Dashboard</span>
			</Link>
			</li>

			<li className="nav-item">
			<Link to="/" className={`nav-link ${page_menu.includes(current_path) ? '' : 'collapsed'}`} data-bs-target="#page-nave" data-bs-toggle="collapse">
			<i className="bx bxs-file"></i><span>Page</span><i className="bi bi-chevron-down ms-auto"></i>
			</Link>
				<ul id="page-nave" className={`nav-content collapse ${page_menu.includes(current_path) ? 'show' : ''}`} data-bs-parent="#sidebar-nav">
				<li>
				<Link to="/page" className={`${current_path==='/page' ? 'active' : ''}`}>
				<i className="bi bi-circle"></i><span>Manage Page</span>
				</Link>
				</li>
				<li>
				<Link to="/block" className={`${current_path==='/block' ? 'active' : ''}`}>
				<i className="bi bi-circle"></i><span>Manage Block</span>
				</Link>
				</li>
				<li>
				<Link to="/banner-category" className={`${current_path==='/banner-category' ? 'active' : ''}`}>
				<i className="bi bi-circle"></i><span>Manage Banner</span>
				</Link>
				</li>				
				</ul>
			</li>

			<li className="nav-item">
			<Link to="/testimonial" className={`nav-link ${testimonial_menu.includes(current_path) ? '' : 'collapsed'}`}>
			<i className="bi bi-aspect-ratio-fill"></i>
			<span>Testimonial</span>
			</Link>
			</li>

			<li className="nav-item">
			<Link to="/" className={`nav-link ${service_menu.includes(current_path) ? '' : 'collapsed'}`} data-bs-target="#service-nav" data-bs-toggle="collapse">
			<i className="bx bxs-florist"></i><span>Service</span><i className="bi bi-chevron-down ms-auto"></i>
			</Link>
				<ul id="service-nav" className={`nav-content collapse ${service_menu.includes(current_path) ? 'show' : ''}`} data-bs-parent="#sidebar-nav">				
				<li>
				<Link to="/service-category" className={`${current_path==='/service-category' ? 'active' : ''}`}>
				<i className="bi bi-circle"></i><span>Service Category</span>
				</Link>
				</li>	
				<li>
				<Link to="/service" className={`${current_path==='/service' ? 'active' : ''}`}>
				<i className="bi bi-circle"></i><span>Service</span>
				</Link>
				</li>			
				</ul>
			</li>

			<li className="nav-item">
			<Link to="/email-templates" className={`nav-link ${emailtemplate_menu.includes(current_path) ? '' : 'collapsed'}`}>
			<i className="bi bi-envelope-fill"></i>
			<span>Email Template</span>
			</Link>
			</li>


			<li className="nav-item">
			<Link to="/" className={`nav-link ${users_menu.includes(current_path) ? '' : 'collapsed'}`} data-bs-target="#users-nav" data-bs-toggle="collapse">
			<i className="bx bxs-user"></i><span>Admin</span><i className="bi bi-chevron-down ms-auto"></i>
			</Link>
				<ul id="users-nav" className={`nav-content collapse ${users_menu.includes(current_path) ? 'show' : ''}`} data-bs-parent="#sidebar-nav">				
				<li>
				<Link to="/users" className={`${current_path==='/users' ? 'active' : ''}`}>
				<i className="bi bi-circle"></i><span>Admin Users</span>
				</Link>
				</li>	
				<li>
				<Link to="/user-type" className={`${current_path==='/user-type' ? 'active' : ''}`}>
				<i className="bi bi-circle"></i><span>Admin User type</span>
				</Link>
				</li>			
				</ul>
			</li>

			<li className="nav-item">
			<Link to="/settings" className={`nav-link ${settings_menu.includes(current_path) ? '' : 'collapsed'}`}>
			<i className="bi bi-gear-fill"></i>
			<span>Settings</span>
			</Link>
			</li>

			<li className="nav-item">
			<Link to="/" className={`nav-link ${faq_menu.includes(current_path) ? '' : 'collapsed'}`} data-bs-target="#faq-nav" data-bs-toggle="collapse">
			<i className="bi bi-patch-question-fill"></i><span>Faq</span><i className="bi bi-chevron-down ms-auto"></i>
			</Link>
				<ul id="faq-nav" className={`nav-content collapse ${faq_menu.includes(current_path) ? 'show' : ''}`} data-bs-parent="#sidebar-nav">				
				<li>
				<Link to="/faq-category" className={`${current_path==='/faq-category' ? 'active' : ''}`}>
				<i className="bi bi-circle"></i><span>Faq Category</span>
				</Link>
				</li>	
				<li>
				<Link to="/faq" className={`${current_path==='/faq' ? 'active' : ''}`}>
				<i className="bi bi-circle"></i><span>Faq</span>
				</Link>
				</li>			
				</ul>
			</li>

			<li className="nav-item">
			<Link to="/" className={`nav-link ${product_menu.includes(current_path) ? '' : 'collapsed'}`} data-bs-target="#product-nav" data-bs-toggle="collapse">
			<i className="bi bi-bag-plus-fill"></i><span>Product</span><i className="bi bi-chevron-down ms-auto"></i>
			</Link>
				<ul id="product-nav" className={`nav-content collapse ${product_menu.includes(current_path) ? 'show' : ''}`} data-bs-parent="#sidebar-nav">				
				<li>
				<Link to="/product-category" className={`${current_path==='/product-category' ? 'active' : ''}`}>
				<i className="bi bi-circle"></i><span>Product Category</span>
				</Link>
				</li>	
				<li>
				<Link to="/product" className={`${current_path==='/product' ? 'active' : ''}`}>
				<i className="bi bi-circle"></i><span>Product</span>
				</Link>
				</li>			
				</ul>
			</li>

			<li className="nav-item">
			<Link to="/imagesize" className={`nav-link ${imagesize_menu.includes(current_path) ? '' : 'collapsed'}`}>
			<i className="bi bi-image"></i>
			<span>Image Size</span>
			</Link>
			</li>

			<li className="nav-item">
			<Link to="/contact" className={`nav-link ${contact_menu.includes(current_path) ? '' : 'collapsed'}`}>
			<i className="bi bi-telegram"></i>
			<span>Contact</span>
			</Link>
			</li>	

		</ul>
		</aside>
	    </>
	);
}
export default Sidebar;