import React, { useState, useEffect } from 'react';
import API from '../../../../config/Api';

let ProfileCard = (props)=>{

    const [admin, setAdmin] = useState(''); 
    const [profile_image, setProfile_image] = useState(''); 
    const [social_media, setSocial_media] = useState(''); 
     	
	useEffect(() => {
        setAdmin(props.admin)
        setProfile_image(props.profile_image)
        setSocial_media(props.admin.social_media)
	},[props]); 

    return(
        <>
        <div className="card">
        <div className="card-body profile-card pt-4 d-flex flex-column align-items-center">
            
            {profile_image ? ( 
			<img src={API.FILE_UPLOAD_URL+'admin-profile/thumb-0/'+profile_image} className="rounded-circle" alt="" />
            ) : (
                <img src="/assets/img/profile-img.png" className="rounded-circle" alt="" />
            )}

            <h2>{admin.name}</h2>
            <h3>{admin.user_type}</h3>
            {social_media &&
                <div className="social-links mt-2">
                    {social_media.twitter &&
                        <a href={social_media.twitter} target="_blank" rel="noreferrer" className="twitter"><i className="bi bi-twitter"></i></a>
                    }
                    {social_media.facebook &&
                        <a href={social_media.facebook} target="_blank" rel="noreferrer" className="facebook"><i className="bi bi-facebook"></i></a>
                    }
                    {social_media.instagram &&
                        <a href={social_media.instagram} target="_blank" rel="noreferrer" className="instagram"><i className="bi bi-instagram"></i></a>
                    }
                    {social_media.linkedin &&
                        <a href={social_media.linkedin} target="_blank" rel="noreferrer" className="linkedin"><i className="bi bi-linkedin"></i></a>
                    }
                </div>
            }
        </div>
        </div>
        </>
    )
}
export default ProfileCard;